import { RouteParams } from "../../domain/interfaces/route-param.interface";
import { useParams } from 'react-router-dom';
import { useMemo } from "react";

/**
 * @hook 
 * @name useRouteParams
 * @description storing our route parameters into context
 * @returns {RouteParams}
 */
const useRouteParams = (): RouteParams => {
    const {workspaceId, orderId, itemId} =
    useParams<
      | "workspaceId"
      | "orderId"
      | "itemId"      
    >();

    const params = useMemo(() => ({
        workspaceId: workspaceId || '',
        orderId: orderId || '',
        itemId: itemId || ''
    }), [workspaceId, orderId, itemId]);

    return params;
};

export default useRouteParams;