import { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGoogleAnalyticsTracing } from '../../infrastructure/hooks/use-google-analytics.hook';
import useT4SAuth from '../../infrastructure/hooks/use-t4s-auth';
import AlertStateContextProvider from '../../infrastructure/providers/alert.context.provider';
import Layout from '../components/Layout';
import Loading from '../pages/Loading/loading.page';
import { Unauthorized } from '../pages/Unauthorized';

interface ProtectedRouteProps {
    component: ReactElement<any, any>;
    header: string;
}

export function ProtectedRoute(props: ProtectedRouteProps) {
    const { component, header } = props;

    useGoogleAnalyticsTracing();
    const { i18n } = useTranslation();

    const { isReady, isCompleted, isOperator } = useT4SAuth();

    useEffect(() => {
        const sessionLng = localStorage.getItem('sessionLng');

        if (sessionLng) {
            i18n.changeLanguage(sessionLng);
        }
    }, [i18n]);

    if (!isOperator && isCompleted) {
        return <Unauthorized />;
    }

    if (isReady && isCompleted) {
        return (
            <AlertStateContextProvider>
                <Layout components={component} header={header} />
            </AlertStateContextProvider>
        );
    }

    return <Loading pending={true} />;
}
