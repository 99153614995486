import { Card, Title } from '@ianneo/component-library';
import { Activites } from '../../components/Activities/activities';

export function Dashboard() {
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    gap: '16px',
                    flexDirection: 'column',
                }}
            >
                <Title>Hi, have a great day</Title>
                <div style={{ display: 'flex', gap: '16px', height: '80dvh' }}>
                    <div style={{ flex: '0 1 30%' }}>
                        <Card
                            title="Recent Activities"
                            style={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                            bodyStyle={{
                                display: 'flex',
                                flex: 1,
                                overflow: 'auto',
                                padding: '8px',
                            }}
                        >
                            <Activites />
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
}
