import { ServiceConfigInterface } from '../../domain/interfaces/config.context.interface';
import { CompanyDocument } from '../../domain/models/company-doc.model';
import { ApiServer } from './api.server';

/**
 * @class
 * @name CompanyDocumentApiClient
 * @description the api client for company documents
 * @author Mark Leung <markleungcl@lfxdigital.com>
 */
export class CompanyDocumentApiClient extends ApiServer<any> {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'company-docs');
    }

    confirm(workspace: string, id: string) {
        console.log(`client<comp-doc>| list(): Enter`);
        console.log(`client<comp-doc>| list(): $workspace = ${workspace}`);
        return super.put('confirm', workspace, id);
    }

    create(workspace: string, document: CompanyDocument) {
        console.log(`client<comp-doc>| create(): Enter`);
        console.log(`client<comp-doc>| create(): $workspace = ${workspace}`);
        console.log(
            `client<comp-doc>| create(): $document = ${JSON.stringify(
                document,
            )}`,
        );
        return super.post('create', workspace, document);
    }

    get(workspace: string, item: string) {
        console.log(`client<comp-doc>| create(): Enter`);
        console.log(`client<comp-doc>| create(): $workspace = ${workspace}`);
        console.log(
            `client<comp-doc>| create(): $document = ${JSON.stringify(
                document,
            )}`,
        );
        return super.get('get', workspace, item);
    }

    list(workspace: string, operator: string) {
        console.log(`client<comp-doc>| list(): Enter`);
        console.log(`client<comp-doc>| list(): $workspace = ${workspace}`);
        return super.get('list', workspace, operator);
    }

    update(workspace: string, id: string, document: CompanyDocument) {
        console.log(`client<comp-doc>| list(): Enter`);
        console.log(`client<comp-doc>| list(): $workspace = ${workspace}`);
        return super.patch('list', workspace, id, document);
    }
}
