import React from 'react';
import { AppContextType } from "../types/app.context";

/**
 * @component 
 * @name AppContext
 * @description These are the context during the app loads up
 * @author Mark Leung <markleungcl@lfxdigital.com>
 */
const AppContext = React.createContext<AppContextType>({} as AppContextType);
export default AppContext;