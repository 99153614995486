import { useQuery } from '@tanstack/react-query';
import { NotificationMessage } from '../../../../domain/models/notification-message.model';
import { NotificationService } from '../../../../domain/services/notification.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useNotificationService } from './use-notification-service';

const getNotifications = async (
    service: NotificationService,
    id: string,
): Promise<NotificationMessage[]> => {
    const response = await service.list(id);

    return response;
};

export function useNotifications() {
    const context = useAppContext();
    const { service } = useNotificationService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: ['notifications', { workspace: context.workspace?.id }],
        queryFn: async () => {
            if (!context.workspace?.id) return;
            return await getNotifications(service, context.workspace.id);
        },
        onError: (err) => {
            setAlert({
                type: 'error',
                message: 'Failed to fetch notification messages',
                title: 'Notification Error',
            });
        },
    });
}
