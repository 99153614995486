import { WorkspaceApiClient } from '../../infrastructure/clients/workspace.client';
import { ServiceConfigInterface } from '../interfaces/config.context.interface';
import { Feature } from '../models/feature.model';
import { Workspace } from '../models/workspace.model';

export class WorkspaceService {
    private server: WorkspaceApiClient;

    constructor(
        config: ServiceConfigInterface,
        private readonly token: string,
    ) {
        this.server = new WorkspaceApiClient(config, token);
    }

    static convert(source: any): Workspace {
        console.log(`service<workspace>| convert(): Enter`);
        console.log(
            `service<workspace>| convert(): $source = ${JSON.stringify(
                source,
            )}`,
        );
        const result: Workspace = {
            id: source.id,
            name: source.name,
            company: {
                id: source.company.id,
                activatedOn: source.company.activatedOn,
                addressLine1: source.company.addressLine1,
                addressLine2: source.company.addressLine2,
                addressLine3: source.company.addressLine3,
                awards: source.company.awards,
                city: source.company.city,
                country: source.company.country,
                createdOn: source.company.createdOn,
                duns: source.company.duns,
                entityName: source.company.entityName,
                fax: source.company.fax,
                geolocation: source.company.geolocation,
                gln: source.company.gln,
                higg: source.company.higg,
                introduction: source.company.introduction,
                lastUpdatedOn: source.company.lastUpdatedOn,
                lfSupplierCode: source.company.lfSupplierCode,
                logo: source.company.logo,
                name: source.company.name,
                oar: source.company.oar,
                phone: source.company.phone,
                postCode: source.company.postCode,
                profile: source.company.profile,
                registrationNumber: source.company.registrationNumber,
                state: source.company.state,
                suburb: source.company.suburb,
                supplyChainNodeType: source.company.supplyChainNodeType,
                vat: source.company.vat,
                verifiedOn: source.company.verifiedOn,
                website: source.company.website,
                xtsCompanyId: source.company.xtsCompanyId,
                zdhcId: source.company.zdhcId,
            },
            createdOn: source.createdOn,
            lastUpdatedOn: source.lastUpdatedOn,
            displayName: source.displayName,
            processes: source.processes,
        };

        return result;
    }

    async get(workspace: string) {
        console.log(`service<workspace>| get(): Enter`);
        console.log(`service<workspace>| get(): $workspace = ${workspace}`);
        return await this.server.get(workspace);
    }

    async getByCompanyName(name: string) {
        console.log(`service<workspace>| getByCompanyName(): Enter`);
        console.log(`service<workspace>| getByCompanyName(): $name = ${name}`);
        const response: any[] = await this.server.getByCompanyName(name);
        return response.map((workspace) => WorkspaceService.convert(workspace));
    }

    async getMembers(workspace: string) {
        console.log(`service<workspace>| getMembers(): Enter`);
        console.log(
            `service<workspace>| getMembers(): $workspace = ${workspace}`,
        );

        return await this.server.getMembers(workspace);
    }

    async getFeatures(): Promise<Feature[]> {
        console.log(`service<workspace>| getFeatures(): Enter`);
        return await this.server.getFeatures();
    }

    async getRoleInfo(workspace: string, id: string) {
        console.log(`service<workspace>| getRoleInfo(): Enter`);
        console.log(
            `service<workspace>| getRoleInfo(): $workspace = ${workspace}`,
        );
        console.log(`service<workspace>| getRoleInfo(): $id = ${id}`);

        return await this.server.getRoleInfo(workspace, id);
    }
}
