import { useState } from "react";
import { AlertMessage } from "../../domain/interfaces/alert.interface";
import { AlertContext } from "../contexts/alert.context";

export interface AlertContextProviderProps {
    children: any;
}

/**
 * @component
 * @name AlertStateContextProvider
 * @description This is a global state provider for wrapping the state needed for alerts
 * @author Mark Leung <markleungcl@lfxdigital.com>
 */
const AlertStateContextProvider: React.FC<AlertContextProviderProps> = ({
    children
}) => {
    const [alert, setAlert] = useState<AlertMessage>();

    return (
      <AlertContext.Provider value={{alert, setAlert}}>
        {children}
      </AlertContext.Provider>
    )
};

export default AlertStateContextProvider;