import {
    AwardApiClient,
    CreateAward,
} from "../../infrastructure/clients/award.client";
import { Award } from "../models/award.modal";
import { Certification } from "../models/certification.model";
import { ServiceConfigInterface } from "./../interfaces/config.context.interface";
/**
 * @class
 * @name AwardService
 * @description The award service to get awards
 * @author Ian Neo <ian.neo@ecloudvalley.com>
 */

export class AwardService {
    private server: AwardApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new AwardApiClient(config, token);
    }

    async list(workspace: string) {
        console.log(`service<award>| list(): Enter`);
        console.log(`service<award>| list(): $workspace = ${workspace}`);
        const result: any[] = await this.server.list(workspace);
        return result.map((r) => this.convert(r));
    }

    async listCerts() {
        console.log(`service<award>| list(): Enter`);
        const result: any[] = await this.server.listCerts();
        return result.map((r) => this.convertToCert(r));
    }

    async get(workspace: string) {
        console.log(`service<award>| get(): Enter`);
        console.log(`service<award>| get(): $workspace = ${workspace}`);

        return this.convert(await this.server.get(workspace));
    }

    async create(workspace: string, award: CreateAward) {
        console.log(`service<award>| create(): Enter`);
        console.log(`service<award>| create(): $workspace = ${workspace}`);
        console.log(
            `service<award>| create(): $document = ${JSON.stringify(award)}`
        );

        return this.convert(await this.server.create(workspace, award));
    }

    async update(workspace: string, id: string, award: Award) {
        console.log(`service<award>| update(): Enter`);
        console.log(`service<award>| update(): $workspace = ${workspace}`);
        console.log(
            `service<award>| update(): $document = ${JSON.stringify(award)}`
        );

        return this.convert(await this.server.update(workspace, id, award));
    }

    private convert(source: any) {
        console.log(`service<award>| convert(): Enter`);
        console.log(
            `service<award>| convert(): $source = ${JSON.stringify(source)}`
        );

        const result: Award = {
            id: source.id,
            auditOrganization: source.auditOrganization,
            certification: source.certification,
            certificateNumber: source.certificateNumber,
            company: source.company,
            createdOn: source.createdOn,
            deletedOn: source.deletedOn,
            documentation: source.documentation,
            expiryDate: source.expiryDate,
            issuedDate: source.issuedDate,
            lastUpdatedOn: source.lastUpdatedOn,
            verifiedOn: source.verifiedOn,
        };

        return result;
    }

    private convertToCert(source: any) {
        console.log("service<award>| convertToCert(): Enter");
        console.log(
            `service<award>| convertToCert(): $source = ${JSON.stringify(
                source
            )}`
        );

        const result: Certification = {
            id: source.id,
            apiProfile: source.apiProfile,
            appliesTo: source.applies,
            awards: source.awards,
            certifyBody: source.certifyBody,
            certificationCode: source.certificationCode,
            createdOn: source.createdOn,
            deletedOn: source.deletedOn,
            lastUpdatedOn: source.lastUpdatedOn,
            name: source.name,
            nature: source.nature,
        };

        return result;
    }
}
