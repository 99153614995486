import { ServiceConfigInterface } from '../../domain/interfaces/config.context.interface';
import { ApiServer } from './api.server';

export class PurchaseOperatorClient extends ApiServer {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'purchases', 'purchase-operator');
    }

    get(workspace: string, searchTerm: string) {
        console.log(`client<purchase-operator>| get(): Enter`);
        console.log(
            `client<purchase-operator>| get(): $workspace = ${workspace}`,
        );
        console.log(
            `client<purchase-operator>| get(): $searchTerm = ${searchTerm}`,
        );

        return super.get('get', workspace, searchTerm);
    }
}
