import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
    PurchaseOrderItem,
    PurchaseOrderTrace,
    PurchaseOrderVersion,
} from '../../../domain/models/purchase.model';
import {
    Connection,
    Node,
    Edge,
    addEdge,
    useEdgesState,
    useNodesState,
    Position,
    ReactFlow,
    Background,
    Controls,
} from 'reactflow';
import { usePurchase } from '../../../infrastructure/hooks/api/purchases/use-purchase';
import { usePurchaseTrace } from '../../../infrastructure/hooks/api/purchases/use-purchase-trace';
import {
    Card,
    DescriptionItemProps,
    Descriptions,
    Drawer,
    Table,
    Typography,
} from '@ianneo/component-library';
import PurchaseTracingNode from './PurchaseTracingNode';

export default function OrderCascadeTrace() {
    const { t } = useTranslation();
    const { id } = useParams();
    const [preview, setPreview] = useState<
        PurchaseOrderVersion & { workspaceId?: string }
    >({});
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [open, setOpen] = useState(false);
    const [root, setRoot] = useState('');
    const [parent] = useState('0');

    const { data: order } = usePurchase({ id });
    const { data: traces } = usePurchaseTrace({ id });
    const { data: previewInfo } = usePurchase({
        id: preview.id,
        customWorkspaceId: preview?.workspaceId,
    });

    const columns = useMemo(
        () => [
            {
                title: t('purchase:detail.overview.productName'),
                dataIndex: ['purchaseables', 'name'],
            },
            {
                title: t('purchase:detail.overview.comment'),
                dataIndex: 'comment',
            },
            {
                title: t('purchase:detail.overview.ppu'),
                render: (_: any, item: PurchaseOrderItem) => (
                    <Typography>
                        {item.ppu} / {item.unit}
                    </Typography>
                ),
            },
            {
                title: t('purchase:detail.overview.quantity'),
                dataIndex: 'quantity',
            },
            {
                title: t('purchase:detail.overview.totalPrice'),
                render: (_: any, item: PurchaseOrderItem) => (
                    <Typography>
                        {calculatePrice(item?.ppu || 0, item?.quantity || 0)}
                    </Typography>
                ),
            },
        ],
        [t],
    );

    const descriptions: DescriptionItemProps[] = [
        {
            label: t('purchase:detail.overview.supplier'),
            value: previewInfo?.owner?.supplier?.seller?.name,
        },
        {
            label: t('purchase:detail.overview.buyer'),
            value: previewInfo?.owner?.supplier?.owner?.name,
        },
        {
            label: t('purchase:detail.overview.currency'),
            value: previewInfo?.owner?.currency,
        },
        {
            label: t('purchase:detail.overview.cascadedTiers'),
            value: 0,
        },
        {
            label: t('purchase:detail.overview.buyDate'),
            value: previewInfo?.createdOn
                ? new Date(previewInfo.createdOn).toLocaleString().slice(0, 10)
                : 'Pending',
        },
        {
            label: t('purchase:detail.overview.template'),
            value: previewInfo?.owner?.rules
                ?.map((rule) => rule.code)
                .join(','),
        },
    ];

    const onConnect = useCallback(
        (params: Edge | Connection) => setEdges((els) => addEdge(params, els)),
        [setEdges],
    );

    const nodeTypes = useMemo(
        () => ({ traceDetails: PurchaseTracingNode }),
        [],
    );

    const onClose = () => {
        setOpen(false);
        setPreview({});
    };

    const calculatePrice = (pricePerUnit: number, quantity: number) => {
        return pricePerUnit * quantity;
    };

    const calculateTotal = () => {
        return previewInfo?.manifest?.reduce(
            (acc, item) =>
                acc + calculatePrice(item.ppu || 0, item.quantity || 0),
            0,
        );
    };

    useEffect(() => {
        const nodes: Node[] = [];
        const flows = {} as Record<string, PurchaseOrderTrace[]>;

        // Initial Group - Root
        nodes.push({
            id: `master-tier`,
            data: {
                label: (
                    <Typography
                        style={{
                            display: 'flex',
                            width: '100%',
                            backgroundColor: '#808A90',
                            padding: '8px',
                            paddingLeft: ' 16px',
                            color: 'white',
                        }}
                    >
                        Tier 0
                    </Typography>
                ),
            },
            className: 'light-haaha',
            position: { x: 0, y: 0 },
            style: {
                width: 400,
                height: order?.owner?.supplierProducer ? 380 : 280,
                padding: '0',
                background: '#F2F2F2',
            },
            draggable: false,
        });

        // Initial node - Root
        nodes.push({
            id: parent || '',
            type: 'traceDetails',
            parentNode: 'master-tier',
            width: 10,
            data: {
                master: true,
                data: order,
                setSelected: setPreview,
                setOpen,
                count: order?.manifest?.length,
            },
            sourcePosition: Position.Right,
            position: { x: 20, y: 50 },
            draggable: false,
        });

        // Splitting the traces into different tiers
        traces?.forEach((trace) => {
            const isExists =
                (flows[trace.tier || 0] || []).findIndex(
                    (x) => x.id === trace.id,
                ) !== -1;

            if (isExists) return;

            flows[trace.tier || 0] = [...(flows[trace.tier || 0] || []), trace];
        });

        // Adding the groups of nodes
        Object.entries(flows).forEach(([tier, traces], index) => {
            nodes.push({
                id: `tier-${tier}`,
                data: {
                    label: (
                        <Typography
                            style={{
                                display: 'flex',
                                width: '100%',
                                backgroundColor: '#808A90',
                                padding: '8px',
                                paddingLeft: ' 16px',
                                color: 'white',
                            }}
                        >
                            Tier {tier}
                        </Typography>
                    ),
                },
                className: 'light',
                position: {
                    x: 500 + 500 * index,
                    y: 0,
                },
                draggable: false,
                style: {
                    width: 400,
                    height: traces.length * 280,
                    padding: '0',
                    background: '#F2F2F2',
                },
            });
        });

        // // Creating the nodes for each tier
        Object.entries(flows).forEach(([tier, traces]) => {
            traces.forEach((trace, yIndex) => {
                const path = trace.path || [];
                const source = path[path.indexOf(trace.to || 0)];

                const isExist =
                    nodes.findIndex(
                        (node) => node.id === source?.toString(),
                    ) !== -1;

                if (isExist) return;

                nodes.push({
                    id: source?.toString() || '',
                    data: {
                        label: trace,
                        setSelected: setPreview,
                        setOpen: setOpen,
                    },
                    draggable: false,
                    sourcePosition: Position.Right,
                    targetPosition: Position.Left,
                    parentNode: `tier-${tier}`,
                    type: 'traceDetails',
                    className: 'light',
                    extent: 'parent',
                    position: {
                        x: 20,
                        y: 50 + 200 * yIndex,
                    },
                });
            });
        });

        setRoot(parent);
        setNodes(nodes);
    }, [order, traces, setNodes, setPreview, parent]);

    useEffect(() => {
        const edges: Edge[] = [];

        // Add the edges
        traces?.forEach((item) => {
            const path = item.path || [];
            const source = path[path.indexOf(item.to || 0) - 1];
            const target = path[path.indexOf(item.to || 0)];

            item.tier === 1
                ? edges.push({
                      id: `${order?.id}-${item.id}`,
                      source: parent,
                      target: target.toString() || '',
                      zIndex: 1,
                  })
                : edges.push({
                      id: `${order?.id}-${item.id}`,
                      source: source?.toString(),
                      target: target.toString() || '',
                      zIndex: 1,
                  });
        });

        setEdges(edges);
    }, [nodes, root, order, traces, setEdges, parent]);

    return (
        <>
            <div style={{ height: '400px', width: '100%' }}>
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    nodeTypes={nodeTypes}
                    defaultViewport={{
                        zoom: 0.7,
                        x: 0,
                        y: 0,
                    }}
                >
                    <Background />
                    <Controls />
                </ReactFlow>
            </div>

            {open ? (
                <Drawer
                    title={preview.owner?.externalDataId}
                    open={open}
                    placement="right"
                    onClose={onClose}
                    size="large"
                    bodyStyle={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '18px',
                    }}
                >
                    <Card title={t('purchase:detail.cascadeTrace.orderTitle')}>
                        <Descriptions items={descriptions}></Descriptions>
                    </Card>

                    <Card
                        title={t('purchase:detail.cascadeTrace.productTitle')}
                    >
                        <Table
                            style={{ minHeight: 0 }}
                            columns={columns}
                            dataSource={previewInfo?.manifest || []}
                            rowKey="id"
                            scroll={{ x: 'max-content' }}
                        />

                        <Typography>
                            {t('purchase:detail.overview.totalPrice')}:{' '}
                            {calculateTotal()}
                        </Typography>
                    </Card>
                </Drawer>
            ) : null}
        </>
    );
}
