import { Link, Table, Title } from '@ianneo/component-library';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Verification } from '../../../domain/models/verification.model';
import { useVerifications } from '../../../infrastructure/hooks/api/verification/use-verifications';

export function Approvals() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { data } = useVerifications();

    const columns = useMemo(
        () => [
            {
                title: t('approval:table.workspace'),
                dataIndex: 'workspace',
                render: (_: any, item: Verification) => {
                    return (
                        <Link onClick={() => navigate(`/approvals/${item.id}`)}>
                            {item.document?.workspace?.company?.name}
                        </Link>
                    );
                },
            },
            {
                title: t('approval:table.nature'),
                dataIndex: 'nature',
                render: (_: any, item: Verification) => {
                    return item.document?.nature;
                },
            },
            {
                title: t('approval:table.documentName'),
                dataIndex: 'documentName',
                render: (_: any, item: Verification) => {
                    return item.document?.file?.originalName;
                },
            },
            {
                title: t('approval:table.documentComment'),
                dataIndex: 'documentComment',
                render: (_: any, item: Verification) => {
                    return item.document?.comment;
                },
            },
        ],
        [t, navigate],
    );

    return (
        <>
            <div style={{ marginBottom: '24px' }}>
                <Title description="View and Manage Approvals">
                    {t('approval:title')}
                </Title>
            </div>

            <div
                style={{
                    display: 'flex',
                    gap: '16px',
                    flexDirection: 'column',
                    height: '100dch',
                    flex: 1,
                }}
            >
                <Table
                    displaySearch
                    dataSource={data?.filter((x) => x.document) || []}
                    columns={columns}
                    rowKey="id"
                />
            </div>
        </>
    );
}
