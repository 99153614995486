import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useNotificationService } from './use-notification-service';

export interface MarkNotificationParams {
    id?: number;
}

export function useMarkNotification() {
    const context = useAppContext();
    const client = useQueryClient();
    const { service } = useNotificationService();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async ({ id }: MarkNotificationParams) => {
            if (!context.workspace?.id || !id) return;
            await service.mark(context.workspace.id, id);
        },
        onSuccess: (_, args) => {
            client.invalidateQueries({
                queryKey: [
                    'notifications',
                    { workspace: context.workspace?.id },
                ],
            });
        },
        onError: () => {
            setAlert({
                type: 'error',
                message: 'Failed to mark notification',
                title: 'Update Notification',
            });
        },
    });
}
