import { SearchOutlined } from '@ant-design/icons';
import {
    Input,
    Link,
    Table,
    Title,
    Typography,
} from '@ianneo/component-library';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PurchaseOrder } from '../../../domain/models/purchase.model';
import { useSearchPurchases } from '../../../infrastructure/hooks/api/operator/use-search-purchases';
import { getPOStatus } from '../../../infrastructure/utils/purchase-order-labeler';

export default function Orders() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);

    const { data, refetch, isFetching } = useSearchPurchases({
        searchTerm: search,
    });

    const onHandleSearch = async () => {
        await refetch();
    };

    useEffect(() => {
        setLoading(isFetching);
    }, [isFetching]);

    const columns = useMemo(
        () => [
            {
                title: t('purchase:listing.poNumber'),
                dataIndex: 'externalDataId',
                render: (_: any, item: PurchaseOrder) => (
                    <Link
                        onClick={() =>
                            navigate(
                                `/workspace/${item.workspace?.id}/orders/${item.id}`,
                            )
                        }
                    >
                        {item.externalDataId}
                    </Link>
                ),
            },
            {
                title: t('purchase:listing.supplier'),
                dataIndex: ['supplier', 'seller', 'name'],
            },
            {
                title: t('purchase:listing.currency'),
                dataIndex: 'currency',
            },
            {
                title: t('purchase:listing.status'),
                dataIndex: 'status',
                render: (_: any, item: PurchaseOrder) => (
                    <Typography>{getPOStatus(item.status)}</Typography>
                ),
            },
            {
                title: t('common:updatedOn'),
                render: (_: any, item: PurchaseOrder) =>
                    item.lastUpdatedOn
                        ? new Date(item.lastUpdatedOn)
                              .toLocaleString()
                              .slice(0, 10)
                        : 'Pending',
            },
        ],
        [t, navigate],
    );

    return (
        <>
            <div style={{ marginBottom: '24px' }}>
                <Title description={t('purchase:description') || ''}>
                    {t('purchase:name')}
                </Title>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '12px',
                        alignItems: 'center',
                    }}
                >
                    <Typography>{t('common:search.label')}: </Typography>
                    <Input
                        style={{ width: '20%' }}
                        value={search}
                        onChange={(event) => setSearch(event.target.value)}
                        placeholder={t('common:search.placeholder') || ''}
                        suffix={<SearchOutlined onClick={onHandleSearch} />}
                        onPressEnter={onHandleSearch}
                    />
                </div>

                <Table
                    columns={columns}
                    dataSource={data || []}
                    loading={loading}
                />
            </div>
        </>
    );
}
