import { VerificationApiClient } from '../../infrastructure/clients/verification.client';
import { ServiceConfigInterface } from '../interfaces/config.context.interface';
import {
    UpdateVerificationParams,
    Verification,
} from '../models/verification.model';

export class VerificationService {
    private server: VerificationApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new VerificationApiClient(config, token);
    }

    async get(id: string): Promise<Verification> {
        console.log(`service<verification>| get(): Enter`);
        console.log(`service<verification>| get(): $id = ${id}`);

        return this.server.get(id) as Promise<Verification>;
    }

    async put(workspace: string, id: string, params: UpdateVerificationParams) {
        console.log(`service<verification>| put(): Enter`);
        console.log(`service<verification>| put(): $workspace = ${workspace}`);
        console.log(`service<verification>| put(): $id = ${id}`);
        console.log(
            `service<verification>| put(): $params = ${JSON.stringify(params)}`,
        );

        return this.server.put(workspace, id, params);
    }

    async list(workspace: string): Promise<Verification[]> {
        console.log(`service<verification>| list(): Enter`);
        console.log(`service<verification>| list(): $workspace = ${workspace}`);

        return this.server.list(workspace) as Promise<Verification[]>;
    }
}
