import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import {
    PurchaseDocumentTrace,
    PurchaseOrderTrace,
    PurchaseOrderVersion,
} from '../../../domain/models/purchase.model';
import { useOrder } from '../../../infrastructure/hooks/api/order/use-order';
import { useOrderSearch } from '../../../infrastructure/hooks/api/order/use-order-search';
import { usePurchaseTrace } from '../../../infrastructure/hooks/api/purchases/use-purchase-trace';
import { SalesOrder } from '../../../domain/models/sales-order.model';

interface OrderMonitorProps {
    data: PurchaseOrderVersion;
}

function generateGraphData(order: SalesOrder | PurchaseDocumentTrace) {
    const documentTypes = new Set<string>();

    let numMatches = 0;

    for (const ruleset of order?.rules || []) {
        for (const doc of ruleset?.documentation || []) {
            documentTypes.add(doc?.document || '');
        }
    }

    for (const doc of order?.documents || []) {
        if (documentTypes.has(doc.type)) {
            numMatches++;
        }
    }

    return {
        name: order?.workspace?.name || '',
        value:
            order?.documents || [].length > 0
                ? (numMatches / documentTypes.size) * 100
                : 0,
    };
}

function calculateMatchPercentage(
    salesOrder?: SalesOrder,
    traces?: PurchaseOrderTrace[],
    documentTraces?: PurchaseDocumentTrace[],
) {
    if (!salesOrder || !traces || !documentTraces) return;

    const graphData = [];
    graphData.push(generateGraphData(salesOrder));

    for (const trace of traces) {
        const order = documentTraces.find((x) => x.id === trace.salesOrder);

        if (order) {
            graphData.push(generateGraphData(order));
        }
    }

    return graphData;
}

export default function OrderMonitor({ data }: OrderMonitorProps) {
    const { data: traces } = usePurchaseTrace({ id: data.owner?.id });
    const { data: salesOrder } = useOrder({ id: data.owner?.id });
    const { data: traceSalesOrders } = useOrderSearch({
        ids: (traces || [])?.map((t) => t.salesOrder),
    });
    const graphData = calculateMatchPercentage(
        salesOrder,
        traces,
        traceSalesOrders,
    );

    return (
        <>
            <div style={{ height: '400px' }}>
                <ResponsiveContainer>
                    <BarChart
                        data={graphData}
                        width={730}
                        height={250}
                        layout="vertical"
                        margin={{ top: 0, right: 50, left: 30, bottom: 0 }}
                    >
                        <XAxis type="number" domain={[0, 100]} />
                        <YAxis
                            type="category"
                            tick={{ fontSize: 10 }}
                            tickLine={false}
                            tickMargin={20}
                            dataKey="name"
                        />
                        <Tooltip />
                        <Legend />
                        <CartesianGrid></CartesianGrid>

                        <Bar dataKey="value" fill="#323232" />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </>
    );
}
