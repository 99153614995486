import { ServiceConfigInterface } from '../../domain/interfaces/config.context.interface';
import {
    UpdateVerificationParams,
    Verification,
} from '../../domain/models/verification.model';
import { ApiServer } from './api.server';

export class VerificationApiClient extends ApiServer<Verification> {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'verification');
    }

    get(id: string) {
        return super.get('get', id);
    }

    put(workspace: string, id: string, params: UpdateVerificationParams) {
        return super.put('put', workspace, id, params);
    }

    list(workspace: string) {
        return super.get('list', workspace);
    }
}
