import { ServiceConfigInterface } from '../../domain/interfaces/config.context.interface';
import { NotificationMessage } from '../../domain/models/notification-message.model';
import { ApiServer } from './api.server';

export class NotificationApiClient extends ApiServer<NotificationMessage> {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'notifications');
    }

    async list(workspace: string): Promise<NotificationMessage[]> {
        const response = await super.get('list', workspace);

        return Array.isArray(response) ? response : [response];
    }

    async mark(workspace: string, id: number): Promise<NotificationMessage> {
        return super.put('mark', workspace, id, {});
    }
}
