import { Button, Modal, Typography } from '@ianneo/component-library';
import { Affix, Alert, Layout, Menu, Select } from 'antd';
import { Content, Header } from 'antd/lib/layout/layout';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertMessage } from '../../../domain/interfaces/alert.interface';
import { useAlertContext } from '../../../infrastructure/hooks/use-alert.hook';
import NavBar from '../NavBar/nav-bar.component';
import './layout.component.css';

export interface LayoutCompProps {
    components: ReactNode;
    children?: never[];
    header?: string | undefined;
    onNavigate: (event: any) => void;
}

const LayoutComponent: React.FC<LayoutCompProps> = ({
    components,
    onNavigate,
}) => {
    const alerts = useAlertContext();

    const { t, i18n } = useTranslation();

    console.log(
        `component<layout>| $alerts.alert = ${JSON.stringify(alerts.alert)}`,
    );
    const [isAlertUp, setIsAlertUp] = useState<boolean>(false);
    // const [menu, setMenu] = useState('dashboard');
    const [changeLngMode, setChangeLngMode] = useState(false);
    const [selectedLng, setSelectedLng] = useState<string>('en');

    const lngOptions = [
        { label: 'English', value: 'en' },
        { label: 'Chinese (Traditional)', value: 'ch' },
    ];

    // Switch workspace for the given user

    const switchLanguage = async () => {
        i18n.changeLanguage(selectedLng);
        localStorage.setItem('sessionLng', selectedLng);

        setChangeLngMode(false);
    };

    // Fetch all the available workspace options for the user

    const getAlert = (alert: AlertMessage) => {
        console.log(
            `component<layout>| getAlert(): $alert = ${JSON.stringify(alert)}`,
        );
        return (
            <Affix offsetBottom={15} style={{ zIndex: 2000 }}>
                <Alert
                    className="alert-box"
                    type={alert.type}
                    message={alert.title}
                    description={alert.message}
                    showIcon
                    closable={true}
                />
            </Affix>
        );
    };

    const menuOptions = [
        { label: t('common:header.dashboard'), key: 'dashboard' },
        { label: t('common:header.approvals'), key: 'approvals' },
        { label: t('common:header.suppliers'), key: 'suppliers' },
        { label: t('common:header.orders'), key: 'orders' },
    ];

    useEffect(() => {
        if (isAlertUp === true) {
            setTimeout(() => {
                setIsAlertUp(false);
                alerts.setAlert(undefined);
            }, alerts?.alert?.timeout || 3000);
        }
    }, [isAlertUp, alerts]);

    useEffect(() => {
        if (alerts.alert !== undefined) {
            setIsAlertUp(true);
        }
    }, [alerts.alert]);

    return (
        <>
            <Layout style={{ minHeight: '100vh' }}>
                <Header
                    style={{
                        backgroundColor: 'white',
                        boxShadow: '0px 1px 2px #ddd',
                        position: 'fixed',
                        display: 'flex',
                        zIndex: 1,
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '24px',
                                flex: 1,
                                // width: '100%',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    justifyContent: 'center',
                                    maxWidth: '25%',
                                }}
                            >
                                <img
                                    style={{
                                        maxHeight: '55%',
                                        maxWidth: '100%',
                                        marginBottom: '0.4em',
                                    }}
                                    src="https://hk.jobsdb.com/en-hk/wp-content/uploads/sites/2/2021/01/vVYMIxN.png"
                                    alt="Application Logo"
                                    aria-label="dashboard"
                                    onClick={() => {
                                        onNavigate('dashboard');
                                        // setMenu('dashboard');
                                    }}
                                />
                            </div>

                            <div style={{ width: '100%' }}>
                                <div style={{ display: 'flex', width: '100%' }}>
                                    <div className="layout--menu">
                                        <Menu
                                            mode="horizontal"
                                            inlineIndent={24}
                                            style={{
                                                display: 'block',
                                                width: '100%',
                                            }}
                                            selectedKeys={['']}
                                        >
                                            {menuOptions.map((item) => (
                                                <Menu.Item
                                                    key={item.key}
                                                    onClick={(item) => {
                                                        onNavigate(item.key);
                                                    }}
                                                >
                                                    {item.label}
                                                </Menu.Item>
                                            ))}
                                        </Menu>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <NavBar setChangeLngMode={setChangeLngMode} />
                        </div>
                    </div>
                </Header>

                <Layout
                    hasSider={true}
                    style={{
                        backgroundColor: '#fff',
                        marginTop: 64,
                    }}
                >
                    <Content className="console">{components}</Content>
                </Layout>
            </Layout>

            {isAlertUp && alerts.alert ? getAlert(alerts.alert) : <></>}

            <Modal
                title={t('common:language.header')}
                open={changeLngMode}
                onCancel={() => setChangeLngMode(false)}
                onOk={switchLanguage}
                closable={false}
                footerChildren={
                    <>
                        <Button
                            mode="comment-hollow"
                            onClick={() => setChangeLngMode(false)}
                        >
                            Cancel
                        </Button>
                        <Button mode="comment" onClick={() => switchLanguage()}>
                            Submit
                        </Button>
                    </>
                }
                okText={t('common:language.switch')}
                cancelText={t('common:cancel')}
            >
                <div
                    style={{
                        display: 'flex',
                        gap: '24px',
                        justifyContent: 'center',
                    }}
                >
                    <Typography style={{ alignSelf: 'center' }}>
                        {t('common:language.selectLanguage')}:
                    </Typography>

                    <Select
                        style={{ width: '60%' }}
                        onChange={(item) => setSelectedLng(item.toString())}
                        value={selectedLng}
                    >
                        {lngOptions.map((item) => (
                            <Select.Option key={item.value}>
                                {item.label}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            </Modal>
        </>
    );
};

export default LayoutComponent;
