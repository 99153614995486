import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useState } from 'react';
import AppContext from '../../../domain/models/app.context.model';
import { User } from '../../../domain/models/user.model';
import useAppConfig from '../../../infrastructure/hooks/use-config.hook';
import UserInfoLayout from './user-info.layout';

export interface UserInfoCompProps {
    setChangeLngMode: (args: boolean) => void;
}

const UserInfoContainer: React.FC<UserInfoCompProps> = ({
    setChangeLngMode,
}) => {
    const appContext = useContext(AppContext);
    const appConfig = useAppConfig();
    const auth0 = useAuth0();

    const [myUser, setMysUser] = useState<User>();

    const UserLogout = async () => {
        auth0.logout({ returnTo: appConfig.webRootUrl });
    };

    // loading the data on the user
    useEffect(() => {
        setMysUser(appContext.user);
    }, [appContext.user]);

    return (
        <>
            <UserInfoLayout
                me={myUser}
                setChangeLngMode={setChangeLngMode}
                onUserLogout={UserLogout}
            />
        </>
    );
};

export default UserInfoContainer;
