import { MeClient } from '../../infrastructure/clients/me.client';
import { ServiceConfigInterface } from '../interfaces/config.context.interface';
import { UserProfileInterface } from '../interfaces/profile.interface';
import { Permission } from '../models/permission.model';
import { User } from '../models/user.model';
import { Workspace } from '../models/workspace.model';
import { WorkspaceService } from './workspace.service';

export class MeService {
    constructor(
        private readonly config: ServiceConfigInterface,
        private readonly token: string,
    ) {}

    private async getPermissions(workspace: string) {
        console.log(`service<my>| getPermissions(): Enter`);
        const client = new MeClient(this.config, this.token);
        return client.getAllowed(workspace);
    }

    private async getUser(): Promise<User> {
        console.log(`service<my>| getUser(): Enter`);
        const client = new MeClient(this.config, this.token);
        const data = await client.get();
        return {
            id: data.id,
            avatar: data.avatar,
            dob: data.dob,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            mobile: data.mobile,
            createdOn: data.createdOn,
            lastUpdatedOn: data.lastUpdatedOn,
        };
    }

    private async getWorkspaces(
        operatorReference?: string,
    ): Promise<Workspace | undefined> {
        console.log(`service<my>| getWorkspaces(): Enter`);
        const client = new MeClient(this.config, this.token);
        const data: any[] = await client.getWorkspaces();

        if (operatorReference) {
            const result = data.find((w) => w.id === operatorReference);
            console.log(
                `service<my>| getWorkspaces(): $result = ${JSON.stringify(
                    result,
                )}`,
            );

            return WorkspaceService.convert(result);
        }

        const workspaceId = localStorage.getItem('sessionWorkspace');
        const sessionWorkspace = data.find((w) => w.id === workspaceId);
        const masterWorkspace = data.find((w) => w.isMasterWorkspace === true);

        if (data.length > 0) {
            const result = WorkspaceService.convert(
                sessionWorkspace || masterWorkspace,
            );
            console.log(
                `service<my>| getWorkspaces(): $result = ${JSON.stringify(
                    result,
                )}`,
            );
            return result;
        } else {
            return undefined;
        }
    }

    async getWorkspaceOptions(): Promise<Workspace[]> {
        console.log(`service<my>| getWorkspaceOptions(): Enter`);
        const client = new MeClient(this.config, this.token);
        const data: any[] = await client.getWorkspaces();

        return data;
    }

    async setup(operatorReference: string): Promise<UserProfileInterface> {
        console.log(`service<my>| setup(): Enter`);
        const user = await this.getUser();
        const workspace = await this.getWorkspaces(operatorReference);
        const permissions: Permission[] = [];

        if (workspace?.id) {
            const acl = await this.getPermissions(workspace.id);
            permissions.push(
                ...acl.map(
                    (a): Permission => ({
                        application: a.split(':')[0],
                        module: a.split(':')[1],
                        action: a.split(':')[2],
                    }),
                ),
            );

            return {
                permissions: permissions,
                user: user,
                workspace: workspace,
            };
        }

        return {
            permissions: [],
            user: undefined,
            workspace: undefined,
        };
    }

    async switch(workspace: Workspace): Promise<UserProfileInterface> {
        console.log(`service<my>| switch(): Enter`);
        const user = await this.getUser();
        const permissions: Permission[] = [];

        if (workspace.id) {
            const acl = await this.getPermissions(workspace.id);
            permissions.push(
                ...acl.map(
                    (a): Permission => ({
                        application: a.split(':')[0],
                        module: a.split(':')[1],
                        action: a.split(':')[2],
                    }),
                ),
            );

            localStorage.setItem('sessionWorkspace', workspace.id);

            return {
                permissions: permissions,
                user: user,
                workspace: workspace,
            };
        } else throw new Error('Unable to load profile');
    }

    async inviteMember(workspace: string, params: any) {
        const client = new MeClient(this.config, this.token);

        return await client.inviteUser(workspace, params);
    }
}
