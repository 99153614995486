import { useMemo } from 'react';
import { ServiceConfigInterface } from '../../domain/interfaces/config.context.interface';

/**
 * @function useAppConfig
 * @description This is the hook for loading process env or other non-user related context data
 * @author Mark Leung <markleungcl@lfxdigital.com>
 */
const useAppConfig = () => {
    const configuration = useMemo(
        (): ServiceConfigInterface => ({
            apiHost: process.env.REACT_APP_API_HOST ?? 'http://localhost:3001',
            helpServerHost:
                process.env.REACT_APP_HELP_HOST ?? 'https://lfxdigital.com/',
            userServiceEndpoint:
                process.env.REACT_APP_USER_ENDPOINT ?? 'http://localhost:3001',
            workspaceServiceEndpoint:
                process.env.REACT_APP_WORKSPACE_ENDPOINT ??
                'http://localhost:3001',
            purchaseServiceEndpoint:
                process.env.REACT_APP_PURCHASE_ENDPOINT ??
                'http://localhost:3002',
            productServiceEndpoint:
                process.env.REACT_APP_PRODUCT_ENDPOINT ??
                'http://localhost:3003',
            inventoryServiceEndpoint:
                process.env.REACT_APP_INVENTORY_ENDPOINT ??
                'http://localhost:3004',
            salesServiceEndpoint:
                process.env.REACT_APP_SALES_ENDPOINT ?? 'http://localhost:3005',
            webRootUrl:
                process.env.REACT_APP_WEB_ROOT ?? 'http://localhost:3000',
        }),
        [],
    );

    return configuration;
};

export default useAppConfig;
