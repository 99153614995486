import { DownloadOutlined } from '@ant-design/icons';
import {
    Button,
    Card,
    Link,
    Table,
    Typography,
} from '@ianneo/component-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CompanyDocument } from '../../../domain/models/company-doc.model';
import { useDocuments } from '../../../infrastructure/hooks/api/company/documents/use-documents';
import useDownloadDocument from '../../../infrastructure/hooks/api/company/documents/use-download-document';
import useDownloadDocuments from '../../../infrastructure/hooks/api/company/documents/use-download-documents';

export default function SupplierDocuments() {
    const { id } = useParams();
    const { t } = useTranslation();
    const { data } = useDocuments({ workspaceId: id });
    const [selectedRows, setSelectedRows] = useState<CompanyDocument[]>([]);

    const downloadDocument = useDownloadDocument();
    const downloadDocuments = useDownloadDocuments();

    const rowSelection = {
        selectedRows,
        onChange: (_: React.Key[], selected: any) => {
            setSelectedRows(selected);
        },
    };

    const onDownload = () => {
        downloadDocuments({ documents: selectedRows });
    };

    const columns = [
        {
            title: t('company:documents.docName'),
            dataIndex: 'file',
            render: (_: any, item: CompanyDocument) => (
                <Typography>{item?.file?.originalName}</Typography>
            ),
        },
        { title: t('company:documents.type'), dataIndex: 'nature' },
        { title: t('company:documents.verifiedOn'), dataIndex: 'verifiedOn' },
        {
            title: t('common:updatedOn'),
            dataIndex: 'lastUpdatedOn',
            render: (_: string, record: CompanyDocument) => {
                return (
                    <Typography>
                        {record.lastUpdatedOn
                            ? new Date(record.lastUpdatedOn)
                                  .toISOString()
                                  .slice(0, 10)
                            : ''}
                    </Typography>
                );
            },
        },
        {
            title: t('common:actions'),
            render: (_: any, item: CompanyDocument) => (
                // Trigger the download document for an individual document
                <Link
                    onClick={async () =>
                        await downloadDocument({ document: item })
                    }
                >
                    {t('common:download')}
                </Link>
            ),
        },
    ];

    return (
        <>
            <Card>
                <Table
                    columns={columns}
                    dataSource={data}
                    rowKey="id"
                    displaySearch
                    rowSelection={rowSelection}
                    actionContent={
                        <>
                            <Button
                                mode="create"
                                onClick={async () => onDownload()}
                            >
                                <DownloadOutlined />
                                {t('common:download')}
                            </Button>
                        </>
                    }
                />
            </Card>
        </>
    );
}
