import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Approvals } from '../pages/Approvals/approvals';
import { Dashboard } from '../pages/Dashboard';
import { ProtectedRoute } from './protected.route';
import { ApprovalDetail } from '../pages/Approvals/approval-detail/approval-detail';
import Suppliers from '../pages/Suppliers/suppliers';
import Orders from '../pages/Orders/orders';
import SupplierDetails from '../pages/Suppliers/supplier-details';
import OrderDetails from '../pages/Orders/order-details';

export function AppRouter() {
    const { isLoading, loginWithRedirect, user } = useAuth0();

    useEffect(() => {
        (async function login() {
            if (!isLoading && !user) {
                await loginWithRedirect();
            }
        })();
    });

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    element={
                        <ProtectedRoute
                            component={<Dashboard />}
                            header="Dashboard"
                        />
                    }
                />

                <Route
                    path="/approvals"
                    element={
                        <ProtectedRoute
                            component={<Approvals />}
                            header="Approvals"
                        />
                    }
                />

                <Route
                    path="/approvals/:id"
                    element={
                        <ProtectedRoute
                            component={<ApprovalDetail />}
                            header="Approvals"
                        />
                    }
                />

                <Route
                    path="/suppliers"
                    element={
                        <ProtectedRoute
                            component={<Suppliers />}
                            header="Suppliers"
                        />
                    }
                />

                <Route
                    path="/suppliers/:id"
                    element={
                        <ProtectedRoute
                            header="Supplier Details"
                            component={<SupplierDetails />}
                        />
                    }
                />

                <Route
                    path="/orders"
                    element={
                        <ProtectedRoute
                            component={<Orders />}
                            header="Orders"
                        />
                    }
                />

                <Route
                    path="/workspace/:workspaceId/orders/:id"
                    element={
                        <ProtectedRoute
                            component={<OrderDetails />}
                            header="Orders"
                        />
                    }
                />
            </Routes>
        </BrowserRouter>
    );
}
