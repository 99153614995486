import { useQuery } from '@tanstack/react-query';
import { PurchaseOrderTrace } from '../../../../domain/models/purchase.model';
import { PurchaseService } from '../../../../domain/services/purchase.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { usePurchaseService } from './use-purchase-service';

interface UsePurchaseTraceInterface {
    id?: string;
}

const getPurchaseTrace = async (
    service: PurchaseService,
    workspace: string,
    id: string,
): Promise<PurchaseOrderTrace[]> => {
    const response = await service.trace(workspace, id);

    return response;
};

export function usePurchaseTrace(args: UsePurchaseTraceInterface) {
    const context = useAppContext();
    const { service } = usePurchaseService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: [
            'purchaseTrace',
            { workspace: context.workspace?.id, id: args.id },
        ],
        queryFn: async () => {
            if (!context.workspace?.id || !args.id) return;
            return await getPurchaseTrace(
                service,
                context.workspace.id,
                args.id,
            );
        },
        onError: (err) => {
            setAlert({
                type: 'error',
                message: 'Failed to fetch purchase trace',
            });
        },
    });
}
