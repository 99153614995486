import {
    LogoutOutlined,
    UserOutlined,
    UserSwitchOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { User } from '../../../domain/models/user.model';
import useAppContext from '../../../infrastructure/hooks/use-context.hook';
import './user-info.component.css';

export interface UserInfoLayoutProps {
    me: User | undefined;
    setChangeLngMode: (open: boolean) => void;
    onUserLogout?: () => Promise<void>;
}

const UserInfoLayout: React.FC<UserInfoLayoutProps> = ({
    me,
    setChangeLngMode,
    onUserLogout,
}) => {
    const { t } = useTranslation();
    const context = useAppContext();

    console.log(`comp<user-info>: $me = ${JSON.stringify(me)}`);

    const popOverContent = (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Button
                type="text"
                style={{ textAlign: 'left' }}
                icon={<UserSwitchOutlined />}
                onClick={() => setChangeLngMode(true)}
            >
                {t('common:profile.switchLanguage')}
            </Button>

            <Button
                type="text"
                style={{ textAlign: 'left' }}
                icon={<LogoutOutlined />}
                onClick={onUserLogout}
            >
                {t('common:profile.logout')}
            </Button>
        </div>
    );

    return (
        <>
            <div className="layout--menu">
                <>{context.workspace?.company?.name}</>
            </div>

            <Popover content={popOverContent} placement="bottomLeft">
                <Button className="user-info" type="text">
                    {me?.avatar ? (
                        <Avatar src={me.avatar.url} />
                    ) : (
                        <Avatar icon={<UserOutlined />} />
                    )}
                    {`${me?.firstName ?? ''} ${me?.lastName ?? ''}`}
                </Button>
            </Popover>
        </>
    );
};

export default UserInfoLayout;
