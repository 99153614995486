import { BellOutlined } from '@ant-design/icons';
import { Typography } from '@ianneo/component-library';
import { useQueryClient } from '@tanstack/react-query';
import { Badge, List, Popover } from 'antd';
import { useCallback, useEffect } from 'react';
import { NotificationMessage } from '../../../domain/models/notification-message.model';
import { socket } from '../../../infrastructure/clients/socket';
import { useMarkNotification } from '../../../infrastructure/hooks/api/notifications/use-mark-notifications';
import { useNotifications } from '../../../infrastructure/hooks/api/notifications/use-notifications';
import useAppContext from '../../../infrastructure/hooks/use-context.hook';
import UserInfo from '../UserInfo';
import './nav-bar.component.css';

export interface NavBarProps {
    setChangeLngMode: (args: boolean) => void;
}

const NavBar: React.FC<NavBarProps> = ({ setChangeLngMode }) => {
    const context = useAppContext();
    const client = useQueryClient();

    const { data } = useNotifications();

    const { mutateAsync: mark } = useMarkNotification();

    const markNotification = async (id: number) => {
        const item = data?.find((x) => x.id === id);

        if (item?.readOn) return;

        await mark({ id });
    };

    const notificationContent = (
        <div
            style={{
                maxHeight: '50vh',
                overflowY: 'auto',
                width: '300px',
            }}
        >
            <List
                dataSource={data || []}
                renderItem={(message) => (
                    <List.Item
                        key={message.id}
                        onClick={() => markNotification(message.id || 0)}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                // alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <Typography
                                style={{ fontSize: '16px', fontWeight: 600 }}
                            >
                                {
                                    message.title?.locales?.find(
                                        (x) => x.localeName === 'en',
                                    )?.text
                                }
                            </Typography>
                            <Typography style={{ fontSize: '12px' }}>
                                {
                                    message.contents?.locales?.find(
                                        (x) => x.localeName === 'en',
                                    )?.text
                                }
                            </Typography>
                        </div>
                    </List.Item>
                )}
            />
        </div>
    );

    const checkWorkspace = useCallback(
        (id: string) => context.workspace?.id === id,
        [context.workspace?.id],
    );

    useEffect(() => {
        function onConnect() {
            console.log(`socket: connecting`);
            socket.emit('get_notifications', {
                workspace: context.workspace?.id,
                user: context.user?.id,
            });
        }

        function onDisconnect() {
            console.log(`socket: disconnecting`);
        }

        function onNewNotification(value: NotificationMessage) {
            console.log(`socket: received new value ${JSON.stringify(value)}`);
            if (!value.owner?.workspace?.id) return;

            if (checkWorkspace(value.owner.workspace.id)) {
                client.setQueriesData(
                    ['notifications', { workspace: context.workspace?.id }],
                    (data: any) => {
                        return [...data, value];
                    },
                );
            }
        }

        function onReadNotification(value: any) {
            console.log(`socket: received read value ${JSON.stringify(value)}`);
        }

        function onGetNotifications(value: any) {
            console.log(`socket: received value ${JSON.stringify(value)}`);
        }

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on('return_notifications', onGetNotifications);
        socket.on('read_notification', onReadNotification);
        socket.on('new_notification', onNewNotification);

        socket.connect();

        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off('new_notification', onNewNotification);
            socket.off('read_notification', onReadNotification);
            socket.off('return_notifications', onGetNotifications);
        };
    }, [context.user?.id, context.workspace?.id, checkWorkspace, client]);

    return (
        <div
            className="navbar-container"
            style={{ display: 'flex', gap: '12px' }}
        >
            <Popover
                content={notificationContent}
                overlayClassName="navbar-popover"
                overlayInnerStyle={{
                    padding: '0 16px',
                }}
                autoAdjustOverflow
                trigger={'hover'}
            >
                <Badge count={data?.filter((x) => !x.readOn).length}>
                    <BellOutlined style={{ fontSize: '18px' }} />
                </Badge>
            </Popover>

            <UserInfo setChangeLngMode={setChangeLngMode} />
        </div>
    );
};

export default NavBar;
