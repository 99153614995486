import { useQuery } from '@tanstack/react-query';
import { Verification } from '../../../../domain/models/verification.model';
import { VerificationService } from '../../../../domain/services/verification.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useVerificationService } from './use-verification-service';

const getVerifications = async (
    service: VerificationService,
    id: string,
): Promise<Verification[]> => {
    const response = await service.list(id);

    return response;
};

export function useVerifications() {
    const context = useAppContext();
    const { service } = useVerificationService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: ['verifications'],
        queryFn: async () => {
            if (!context.workspace?.id) return;

            return await getVerifications(service, context.workspace.id);
        },
        retry: false,
        onError: (err) => {
            console.error(err);
            setAlert({
                type: 'error',
                message: 'Failed to fetch verifications',
                title: 'Verification Error ',
            });
        },
    });
}
