import { useContext, useMemo } from "react";
import AppContext from "../../domain/models/app.context.model";
import useAppConfig from "./use-config.hook";
import { useErrorHandler } from "./use-error.hook";
import useRouteParams from "./use-params.hook";

const useAppContext = () => {
    const baseContext = useContext(AppContext);
    const configuration = useAppConfig();
    const routeParams = useRouteParams();
    const errorHandler = useErrorHandler();

  return useMemo(
    () => ({
      ...baseContext,
      configuration,
      routeParams,
      errorHandler,
    //   activeLanguage: i18next.language,
    }),
    [baseContext, configuration, errorHandler, routeParams]
  );

};

export default useAppContext;