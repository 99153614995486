import { CheckOutlined } from '@ant-design/icons';
import {
    Button,
    Collapse,
    CollapsePanel,
    Link,
    Popover,
    Progress,
    Table,
    TableProps,
    Typography,
    Upload,
    UploadFile,
} from '@ianneo/component-library';
import axios from 'axios';
import { saveAs } from 'file-saver';

import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SupportDocType } from '../../../domain/enums/support-doctype.enum';
import { PurchaseOrderVersion } from '../../../domain/models/purchase.model';
import { SalesOrderDocument } from '../../../domain/models/sales-order.model';
import { Attachment } from '../../../domain/types/attachment.type';
import { useDownloadFile } from '../../../infrastructure/hooks/api/file/use-download-file';
import { useOrder } from '../../../infrastructure/hooks/api/order/use-order';
import { useAttachOrder } from '../../../infrastructure/hooks/api/order/use-order-attach';
import { useOrderSearch } from '../../../infrastructure/hooks/api/order/use-order-search';
import { useDownloadPurchaseDocuments } from '../../../infrastructure/hooks/api/purchases/use-download-purchase-documents';
import { usePurchaseTrace } from '../../../infrastructure/hooks/api/purchases/use-purchase-trace';
import { useUploadPurchaseFiles } from '../../../infrastructure/hooks/api/purchases/use-upload-purchase-files';
import useAppContext from '../../../infrastructure/hooks/use-context.hook';
import { getDocumentValue } from '../../../infrastructure/utils/document-type-labeler';

interface OrderDocumentsProps {
    data: PurchaseOrderVersion;
}

export interface PurchaseDocument {
    id?: number;
    tier?: number;
    poNumber?: string;
    salesOrder?: number;
    workspaceId?: string;
    rules?: any;
    documents: SalesOrderDocument[];
    buyer?: string;
    supplier?: string;
}

interface PopoverProps {
    tier?: number;
    index?: number;
}

export default function OrderDocuments({ data }: OrderDocumentsProps) {
    const { t } = useTranslation();
    const context = useAppContext();
    const [documentTypes, setDocumentTypes] = useState<SupportDocType[]>([]);
    const [documents, setDocuments] = useState<PurchaseDocument[][]>([]);
    const [percentage, setPercentage] = useState(0);
    const [files, setFiles] = useState<UploadFile[]>([]);
    const [popoverStates, setPopoverStates] = useState<PopoverProps>({});
    const [loading, setLoading] = useState(false);

    const { data: traces } = usePurchaseTrace({ id: data.owner?.id });
    const { data: salesOrder } = useOrder({ id: data.owner?.id });
    const { data: traceSalesOrders } = useOrderSearch({
        ids: (traces || [])?.map((t) => t.salesOrder),
    });

    const { mutateAsync: download } = useDownloadFile();
    const { mutateAsync: downloads } = useDownloadPurchaseDocuments();
    const { mutateAsync: upload } = useUploadPurchaseFiles();
    const { mutateAsync: attach } = useAttachOrder();

    const downloadFile = useCallback(
        async (file: Attachment, tier: number) => {
            const response = await download({ attachment: file });
            if (!response?.token) return;

            const fileBlob = await axios.get(response.token, {
                responseType: 'blob',
            });

            saveAs(fileBlob.data, `Tier ${tier + 1} - ${file.originalName}`);
        },
        [download],
    );

    const togglePopover = useCallback(
        (tier: number, index: number) => {
            const item = popoverStates;
            item.tier = item.tier ? undefined : tier + 1;
            item.index = item.index ? undefined : index + 1;

            setPopoverStates((prev) => ({ ...prev, ...item }));
        },
        [popoverStates],
    );

    const downloadFiles = useCallback(async () => {
        const files: Attachment[] = [];
        setLoading(true);

        const clonedDocuments = _.cloneDeep(documents);

        clonedDocuments.forEach((doc, index) => {
            doc.forEach((d) => {
                d.documents?.forEach((f) => {
                    f.file.originalName = `Tier ${index + 1} - ${
                        f.file.originalName
                    }`;
                    files.push(f.file);
                });
            });
        });

        await downloads({ files });
        setLoading(false);
    }, [documents, downloads]);

    const uploadFile = useCallback(
        async (item: PurchaseDocument, type: SupportDocType) => {
            if (!item.salesOrder) return;

            const request: { documents: SalesOrderDocument[] } = {
                documents: [],
            };
            request.documents.push(...item.documents);

            const uploadRequest = await upload({ type, files });
            request.documents.push(...(uploadRequest || []));

            await attach({
                id: item.salesOrder,
                documents: request.documents,
                poId: data?.owner?.id || '',
            });

            setFiles([]);
        },
        [files, upload, attach, data.owner],
    );

    useEffect(() => {
        console.log(popoverStates);
    }, [popoverStates]);

    const columns = useMemo(() => {
        const col: TableProps['columns'] = [
            {
                title: t('purchase:detail.documents.poNumber'),
                dataIndex: 'poNumber',
            },
            {
                title: t('purchase:detail.documents.buyer'),
                dataIndex: 'buyer',
            },
            {
                title: t('purchase:detail.documents.supplier'),
                dataIndex: 'supplier',
            },
        ];

        documentTypes.forEach((type, index) => {
            col.push({
                title: getDocumentValue(type),
                dataIndex: type,
                render: (_: any, item: PurchaseDocument) => {
                    const documents = item.documents.filter(
                        (x) => x.type === type,
                    );

                    return (
                        <>
                            <Popover
                                title={getDocumentValue(type)}
                                trigger={'click'}
                                key={index}
                                open={
                                    popoverStates.tier ===
                                        (item.tier || 0) + 1 &&
                                    popoverStates.index === index + 1
                                }
                                onOpenChange={() => {
                                    setFiles([]);
                                    togglePopover(item.tier || 0, index);
                                }}
                                content={
                                    <>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    marginBottom: '12px',
                                                }}
                                            >
                                                {documents.length > 0
                                                    ? documents.map(
                                                          (doc, index) => (
                                                              <Typography
                                                                  id={doc.id?.toString()}
                                                              >
                                                                  <div
                                                                      style={{
                                                                          display:
                                                                              'flex',
                                                                          gap: '24px',
                                                                      }}
                                                                  >
                                                                      <div>
                                                                          {
                                                                              doc
                                                                                  .file
                                                                                  .originalName
                                                                          }
                                                                          <CheckOutlined
                                                                              style={{
                                                                                  color: '#1890ff',
                                                                              }}
                                                                          />
                                                                      </div>

                                                                      <Link
                                                                          onClick={async () =>
                                                                              await downloadFile(
                                                                                  doc.file,
                                                                                  item.tier ||
                                                                                      0,
                                                                              )
                                                                          }
                                                                      >
                                                                          {t(
                                                                              'common:download',
                                                                          )}
                                                                      </Link>
                                                                  </div>
                                                              </Typography>
                                                          ),
                                                      )
                                                    : ''}

                                                {files.length > 0 ? (
                                                    <>
                                                        <div
                                                            style={{
                                                                marginBottom:
                                                                    '12px',
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                            }}
                                                        >
                                                            {files.map(
                                                                (file) => (
                                                                    <Typography
                                                                        id={
                                                                            file.uid
                                                                        }
                                                                    >
                                                                        {
                                                                            file.name
                                                                        }
                                                                    </Typography>
                                                                ),
                                                            )}
                                                        </div>
                                                    </>
                                                ) : null}
                                            </div>

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <Upload
                                                    showUploadList={false}
                                                    beforeUpload={() => false}
                                                    fileList={files}
                                                    setFiles={setFiles}
                                                    multiple
                                                >
                                                    <Link>
                                                        {t(
                                                            'purchase:detail.documents.uploadMore',
                                                        )}
                                                    </Link>
                                                </Upload>

                                                <Link
                                                    onClick={async () => {
                                                        if (files.length > 0) {
                                                            await uploadFile(
                                                                item,
                                                                type,
                                                            );
                                                        } else {
                                                            togglePopover(
                                                                item.tier || 0,
                                                                index,
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {t(
                                                        'purchase:detail.documents.finish',
                                                    )}
                                                </Link>
                                            </div>
                                        </div>
                                    </>
                                }
                            >
                                {documents.length > 0 ? (
                                    <Link style={{ color: '#4CA803D9' }}>
                                        {t(
                                            'purchase:detail.documents.uploaded',
                                        )}
                                    </Link>
                                ) : item?.rules?.includes(type) ? (
                                    <Link style={{ color: 'red' }}>
                                        {t('common:upload')}
                                    </Link>
                                ) : (
                                    <Link style={{ color: 'grey' }}>
                                        {t('common:upload')}
                                    </Link>
                                )}
                            </Popover>
                        </>
                    );
                },
            });
        });

        return col;
    }, [
        t,
        documentTypes,
        files,
        popoverStates,
        downloadFile,
        uploadFile,
        togglePopover,
    ]);

    useEffect(() => {
        const rules: SupportDocType[] = [];
        salesOrder?.rules?.forEach((rule) =>
            rule.documentation?.forEach((doc) =>
                doc.document ? rules.push(doc.document) : null,
            ),
        );

        traceSalesOrders?.forEach((so) =>
            so.rules?.forEach((rule) =>
                rule.documentation?.forEach(
                    (doc) => doc.document && rules.push(doc.document),
                ),
            ),
        );

        setDocumentTypes(
            rules.filter((value, index, self) => self.indexOf(value) === index),
        );
    }, [salesOrder, data, traces, traceSalesOrders]);

    useEffect(() => {
        const consolidatedDocuments: PurchaseDocument[] = [];

        const lastRule = salesOrder?.rules?.at(salesOrder.rules?.length - 1);
        const rules = lastRule?.documentation
            ?.filter((doc) => {
                const found =
                    context?.workspace?.company?.supplyChainNodeType?.some(
                        (a) => {
                            return a.toString() === doc.appliesTo?.toString();
                        },
                    );
                return found || false;
            })
            ?.map((doc) => doc.document);

        consolidatedDocuments.push({
            tier: 0,
            poNumber: salesOrder?.poExternalRefId,
            salesOrder: Number(salesOrder?.id || 0),
            workspaceId: salesOrder?.workspace?.id || '',
            rules,
            documents: salesOrder?.documents || [],
            buyer: salesOrder?.customer?.buyer?.name,
            supplier: salesOrder?.customer?.owner?.name,
        });

        traceSalesOrders?.forEach((doc) => {
            const trace = traces?.find(
                (item) => item.salesOrder === Number(doc.id),
            );

            const lastRule = doc.rules?.at(doc.rules.length - 1);
            const rules = lastRule?.documentation
                ?.filter((document) => {
                    const found = doc.customer?.owner?.operations?.some(
                        (a: any) => {
                            return (
                                a.toString() === document.appliesTo?.toString()
                            );
                        },
                    );

                    return found || false;
                })
                ?.map((document) => document.document);

            consolidatedDocuments.push({
                tier: trace?.tier,
                poNumber: trace?.externalDataId,
                salesOrder: trace?.salesOrder,
                workspaceId: doc.workspace?.id,
                rules,
                documents: doc.documents || [],
                buyer: doc.customer?.buyer?.name,
                supplier: doc.customer?.owner?.name,
            });
        });

        setDocuments(consolidatedDocuments.map((x) => [x]));
    }, [traceSalesOrders, traces, salesOrder, context.workspace]);

    useEffect(() => {
        const consolidatedArray = [salesOrder, ...(traceSalesOrders || [])];

        let totalDocuments = 0;
        let uploadedDocuments = 0;

        consolidatedArray.forEach((item) => {
            const operations = item?.workspace?.operations || [];
            item?.rules?.forEach((rule) => {
                const rules = rule.documentation?.filter((r) =>
                    item.documents?.find((i) => i.type === r.document),
                );

                // totalDocuments += rule.documentation?.length || 0;
                const totalRequiredDocuments = rule.documentation?.filter((x) =>
                    x.appliesTo?.some((appliesTo) =>
                        operations.includes(appliesTo),
                    ),
                );
                totalDocuments += totalRequiredDocuments?.length || 0;
                uploadedDocuments += rules?.length || 0;
            });
        });

        console.log(consolidatedArray);

        const percentage = Math.round(
            (uploadedDocuments / totalDocuments) * 100,
        );

        setPercentage(percentage);
    }, [salesOrder, traceSalesOrders]);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                }}
            >
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '60%',
                            alignItems: 'center',
                        }}
                    >
                        <Typography disabled style={{ flex: '1 0 40%' }}>
                            {t(
                                'purchase:detail.documents.documentCompleteness',
                            )}
                        </Typography>
                        <Progress
                            percent={percentage}
                            style={{ flex: '1 0 60%' }}
                        />
                    </div>
                    <Button
                        mode="create"
                        onClick={downloadFiles}
                        loading={loading}
                    >
                        Download All
                    </Button>
                </div>

                <Collapse>
                    {documents.map((doc, index) => (
                        <CollapsePanel
                            header={`Tier ${index + 1}`}
                            key={`tier-${index}`}
                        >
                            <Table
                                key={`tier-${index}`}
                                columns={columns}
                                dataSource={doc}
                                style={{ minHeight: 0 }}
                                pagination={false}
                            />
                        </CollapsePanel>
                    ))}
                </Collapse>
            </div>
        </>
    );
}
