import { ServiceConfigInterface } from '../../domain/interfaces/config.context.interface';
import { CreateTaskModel, Task } from '../../domain/models/task.model';
import { ApiServer } from './api.server';

export class TaskApiClient extends ApiServer<Task> {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'task');
    }

    get(workspace: string): Promise<Task | Task[]> {
        console.log(`client<user>| get(): Enter`);
        console.log(`client<user>| get(): $workspace = ${workspace}`);

        return super.get('list', workspace);
    }

    create(
        workspace: string,
        userId: string,
        task: CreateTaskModel,
    ): Promise<Task> {
        console.log(`client<user>| list(): Enter`);
        console.log(`client<user>| list(): $workspace = ${workspace}`);
        console.log(`client<user>| list(): $userId = ${userId}`);

        return super.post('create', workspace, userId, task);
    }

    delete(id: string) {
        console.log(`client<user>| delete(): Enter`);
        console.log(`client<user>| delete(): $id = ${id}`);

        return super.delete('delete', id);
    }
}
