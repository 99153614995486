import { useMutation } from '@tanstack/react-query';
import {
    AttachmentResultObject,
    FileService,
} from '../../../../domain/services/file.service';
import { Attachment } from '../../../../domain/types/attachment.type';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useFileService } from './use-files-service';

interface UseDownloadInterface {
    attachment?: Attachment;
}

const download = async (
    service: FileService,
    id: string,
    attachment: Attachment,
): Promise<AttachmentResultObject> => {
    const response = await service.download(id, attachment);

    return response;
};

export function useDownloadFile() {
    const context = useAppContext();
    const { service } = useFileService();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async ({ attachment }: UseDownloadInterface) => {
            if (!context.workspace?.id || !attachment) return;

            return await download(service, context.workspace.id, attachment);
        },
        onSuccess: () => {},
        onError: () => {
            setAlert({
                type: 'error',
                message: 'Failed to download file',
                title: 'Download Files',
            });
        },
    });
}
