import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useFileService } from '../file/use-files-service';
import { useAwardService } from './use-award-service';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FileService } from '../../../../domain/services/file.service';
import { AwardService } from '../../../../domain/services/award.service';
import { Award } from '../../../../domain/models/award.modal';
import { UploadFile } from 'antd';
import axios from 'axios';

interface UploadAwardInterface {
    awards: Award[];
    files: UploadFile[];
}

const uploadAward = async (
    fService: FileService,
    aService: AwardService,
    id: string,
    awards: Award[],
    files: UploadFile[],
) => {
    return await Promise.all(
        awards.map(async (award) => {
            const file = files.find(
                (file: UploadFile) =>
                    file.name === award.documentation.originalName,
            );

            if (file && file.originFileObj) {
                const token = await fService.upload(id, 'certificate', {
                    filename: award.documentation.assetName,
                    contentType: award.documentation.contentType,
                });

                await axios.put(token.token, file.originFileObj, {
                    headers: {
                        'x-ms-blob-type': 'BlockBlob',
                        'Content-Type': award.documentation.contentType,
                    },
                });

                await aService.create(id, {
                    auditOrganization: award.auditOrganization || '',
                    certification: award.certification.id || '',
                    certificateNumber: award.certificateNumber || '',
                    documentation: {
                        ...award.documentation,
                        assetName: token.attachment.assetName,
                    },
                    expiryDate: award.expiryDate || new Date(),
                    issuedDate: award.issuedDate || new Date(),
                });
            }
        }),
    );
};

export function useUploadAward() {
    const context = useAppContext();
    const { service: fileService } = useFileService();
    const { service: awardService } = useAwardService();
    const { setAlert } = useAlertContext();
    const client = useQueryClient();

    return useMutation({
        mutationFn: async ({ awards, files }: UploadAwardInterface) => {
            if (!context.workspace?.id) return;

            uploadAward(
                fileService,
                awardService,
                context.workspace.id,
                awards,
                files,
            );
        },
        onSuccess: () => {
            client.invalidateQueries({
                queryKey: ['companyAwards', { type: context.workspace?.id }],
            });
        },
        onError: (err) => {
            setAlert({
                type: 'error',
                title: 'Upload Award Failed',
                message: 'Failed to upload award',
            });
        },
    });
}
