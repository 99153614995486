import {
    Card,
    Descriptions,
    InfoCard,
    RadioGroup,
    Title,
    Typography,
} from '@ianneo/component-library';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useWorkspace } from '../../../infrastructure/hooks/api/workspaces/use-workspace';
import SupplierDocuments from './supplier-documents';
import SupplierCertifications from './supplier-certifications';

export interface DescripionItemType {
    label: string;
    value?: string | number | boolean;
}

export default function SupplierDetails() {
    const { id } = useParams();
    const { t } = useTranslation();

    const { data } = useWorkspace({ id: id || '' });
    const [menu, setMenu] = useState('details');
    const menuOptions = useMemo(
        () => [
            { label: t('company:menu.details'), value: 'details' },
            { label: t('company:menu.documents'), value: 'documents' },
            {
                label: t('company:menu.certifications'),
                value: 'certifications',
            },
        ],
        [t],
    );

    useEffect(() => {
        console.log('huh');
        console.log(data);
    }, [data]);

    const descriptions: DescripionItemType[] = useMemo(
        () => [
            {
                label: t('company:details.entityName'),
                value: data?.company?.name,
            },
            { label: t('company:details.higgId'), value: data?.company?.higg },
            {
                label: t('company:details.companyUrl'),
                value: data?.company?.website,
            },
            {
                label: t('company:details.lfSupplierCode'),
                value: data?.company?.lfSupplierCode,
            },
            {
                label: t('company:details.capacity'),
                value: data?.company?.capacity,
            },
            { label: t('company:details.oarId'), value: data?.company?.oar },
            {
                label: t('company:details.contactBusiness'),
                value: data?.company?.contactBusiness?.phone,
            },
            {
                label: t('company:details.contactCompliance'),
                value: data?.company?.contactCompliance?.phone,
            },
            {
                label: t('company:details.country'),
                value: data?.company?.country,
            },
            {
                label: t('company:details.dbaName'),
                value: data?.company?.dbaName,
            },
            {
                label: t('company:details.dbaRegistration'),
                value: data?.company?.dbaRegistration,
            },
            {
                label: t('company:details.dbaReference'),
                value: data?.company?.dbaReference,
            },
            {
                label: t('company:details.employees'),
                value: data?.company?.employees,
            },
            { label: t('company:details.gln'), value: data?.company?.gln },
            {
                label: t('company:details.phoneNumber'),
                value: data?.company?.phone,
            },
            { label: t('company:details.city'), value: data?.company?.city },
            { label: t('company:details.duns'), value: data?.company?.duns },
            {
                label: t('company:details.address'),
                value: data?.company?.addressLine1,
            },
            {
                label: t('company:details.brNo'),
                value: data?.company?.registrationNumber,
            },
            { label: t('company:details.vatId'), value: data?.company?.vat },
            {
                label: t('company:details.zhdcId'),
                value: data?.company?.zdhcId,
            },
            {
                label: t('company:details.memberSince'),
                value: data?.createdOn
                    ? new Date(data?.createdOn).toLocaleDateString()
                    : undefined,
            },
        ],
        [data, t],
    );

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '24px',
                }}
            >
                <Title description={t('company:description') || ''}>
                    {t('company:name')}
                </Title>

                <InfoCard
                    showIcon
                    tags={
                        data?.company?.supplyChainNodeType?.map((x) =>
                            x.toString(),
                        ) || []
                    }
                    infoTitle={data?.name}
                    information={
                        <>
                            <Typography>
                                {t('company:header.supplierCode')}:{' '}
                                {data?.company?.lfSupplierCode
                                    ? data?.company?.lfSupplierCode
                                    : 'N/A'}
                            </Typography>

                            <Typography style={{ fontSize: '12px' }}>
                                {t('company:header.introduction')}:{' '}
                                {data?.company?.introduction
                                    ? data.company?.introduction
                                    : 'N/A'}
                            </Typography>
                        </>
                    }
                />

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '24px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <RadioGroup
                            style={{ flexDirection: 'row', display: 'flex' }}
                            items={menuOptions}
                            defaultValue={menu}
                            onChange={(e) => setMenu(e.target.value)}
                        />
                    </div>

                    {menu === 'details' ? (
                        <>
                            <Card>
                                <Descriptions
                                    items={descriptions}
                                ></Descriptions>
                            </Card>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    gap: '32px',
                                }}
                            >
                                <Card
                                    style={{ flex: 1, minHeight: '200px' }}
                                    title={t(
                                        'company:details.overallPerformance',
                                    )}
                                ></Card>
                                <Card
                                    style={{ flex: 1 }}
                                    title={t(
                                        'company:details.networkRiskDetection',
                                    )}
                                ></Card>
                            </div>
                        </>
                    ) : null}
                </div>

                {menu === 'documents' ? <SupplierDocuments /> : null}

                {menu === 'certifications' ? <SupplierCertifications /> : null}
            </div>
        </>
    );
}
