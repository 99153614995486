import { useQuery } from '@tanstack/react-query';
import { CompanyDocument } from '../../../../../domain/models/company-doc.model';
import { CompanyDocumentService } from '../../../../../domain/services/company-docs.service';
import { useAlertContext } from '../../../use-alert.hook';
import useAppContext from '../../../use-context.hook';
import { useCompanyDocumentService } from './use-document-service';

interface UseDocumentInterface {
    workspaceId?: string;
}

const getDocuments = async (
    service: CompanyDocumentService,
    id: string,
    operator: string,
): Promise<CompanyDocument[]> => {
    const response = await service.list(id, operator);

    return response;
};

export function useDocuments(args: UseDocumentInterface) {
    const context = useAppContext();
    const { service } = useCompanyDocumentService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: [
            'companyDocuments',
            {
                type: args.workspaceId
                    ? args.workspaceId
                    : context.workspace?.id,
            },
        ],
        queryFn: async () => {
            if (!context.workspace?.id) return;

            return await getDocuments(
                service,
                args.workspaceId ? args.workspaceId : context.workspace.id,
                context.workspace.id,
            );
        },
        onError: (err) => {
            setAlert({
                type: 'error',
                message: 'Failed to fetch company documents information',
            });
        },
    });
}
