import { Button, Input } from '@ianneo/component-library';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDownloadFile } from '../../../../infrastructure/hooks/api/file/use-download-file';
import { useUpdateVerification } from '../../../../infrastructure/hooks/api/verification/use-update-verification';
import { useVerification } from '../../../../infrastructure/hooks/api/verification/use-verification';
import { PDFViewer } from '../../../components/PDFViewer/pdf-viewer';
import { useAlertContext } from '../../../../infrastructure/hooks/use-alert.hook';

export function ApprovalDetail() {
    const { t } = useTranslation();
    const { id } = useParams();
    const { setAlert } = useAlertContext();
    const [token, setToken] = useState('');
    const [comment, setComment] = useState('');
    const [loaded, setLoaded] = useState(false);

    const { data } = useVerification({ id });
    const { mutateAsync: approve } = useUpdateVerification();
    const { mutateAsync: downloadFile } = useDownloadFile();

    const submit = async (result: boolean) => {
        if (!id) return;

        const commentRegex = new RegExp('.*[^ ].*');

        if (!commentRegex.test(comment)) {
            setAlert({
                message: t('approval:detail.commentRequired') || '',
                type: 'error',
            });

            return;
        }

        await approve({
            id: id,
            params: {
                result: result,
                comment: comment,
            },
        });

        setComment('');
        setLoaded(true);
    };

    const getImages = useCallback(async () => {
        const res = await downloadFile({ attachment: data?.document?.file });

        setToken((token) => (res?.token === token ? token : res?.token || ''));
    }, [downloadFile, data?.document?.file]);

    useEffect(() => {
        if (data) {
            getImages();
        }
    }, [data, getImages]);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    gap: '12px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flex: 3,
                        height: '100%',
                        gap: '12px',
                        flexDirection: 'column',
                    }}
                >
                    <PDFViewer
                        pdfUrl={token}
                        style={{ flex: 1, height: '90%' }}
                        loaded={loaded}
                    />

                    <div style={{ display: 'flex', gap: '12px' }}>
                        <Input
                            value={comment}
                            onChange={(event) => setComment(event.target.value)}
                        />

                        <div
                            style={{
                                display: 'flex',
                                gap: '12px',
                                justifyContent: 'right',
                            }}
                        >
                            <Button mode="delete" onClick={() => submit(false)}>
                                {t('approval:detail.reject')}
                            </Button>

                            <Button mode="create" onClick={() => submit(true)}>
                                {t('approval:detail.approve')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
