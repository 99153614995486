import { List } from '@ianneo/component-library';
import { useTasks } from '../../../infrastructure/hooks/api/task/use-tasks';

export function Activites() {
    const { data } = useTasks();

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    gap: '16px',
                    flexDirection: 'column',
                    flex: 1,
                    height: '100%',
                }}
            >
                <List
                    dataSource={data}
                    withViewMore
                    rowKeyId={['content', 'locales', '0', 'text']}
                    viewAction={() => {}}
                ></List>
            </div>
        </>
    );
}
