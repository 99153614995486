import { useQuery } from '@tanstack/react-query';
import { Verification } from '../../../../domain/models/verification.model';
import { VerificationService } from '../../../../domain/services/verification.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useVerificationService } from './use-verification-service';

interface UseVerificationInterface {
    id?: string;
}

const getVerification = async (
    service: VerificationService,
    id: string,
): Promise<Verification> => {
    const response = await service.get(id);

    return response;
};

export function useVerification(args: UseVerificationInterface) {
    const context = useAppContext();
    const { service } = useVerificationService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: [
            'verification',
            { workspace: context.workspace?.id, id: args.id },
        ],
        queryFn: async () => {
            if (!context.workspace?.id || !args.id) return;
            return await getVerification(service, args.id);
        },
        onError: (err) => {
            setAlert({
                type: 'error',
                message: 'Failed to fetch verification information',
            });
        },
    });
}
