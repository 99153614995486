import { ServiceConfigInterface } from "../../domain/interfaces/config.context.interface";
import { Award } from "../../domain/models/award.modal";
import { Attachment } from "../../domain/types/attachment.type";
import { ApiServer } from "./api.server";

/**
 * @class
 * @name CompanyDocumentApiClient
 * @description the api client for company documents
 * @author Mark Leung <markleungcl@lfxdigital.com>
 */

export interface CreateAward {
    auditOrganization: string;
    certification: string;
    certificateNumber: string;
    documentation: Attachment;
    expiryDate: Date;
    issuedDate: Date
}

export class AwardApiClient extends ApiServer<any> {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, "workspaces", "award");
    }

    list(workspace: string) {
        console.log(`client<award>| list(): Enter`);
        console.log(`client<award>| list(): $workspace = ${workspace}`);
        return super.get("list", workspace);
    }

    listCerts() {
        console.log(`client<award>| list(): Enter`);
        return super.get("search-certs");
    }

    create(workspace: string, award: CreateAward) {
        console.log(`client<award>| create(): Enter`);
        console.log(`client<award>| create(): $workspace = ${workspace}`);
        console.log(
            `client<award>| create(): $document = ${JSON.stringify(award)}`
        );
        return super.post("create", workspace, award);
    }

    update(workspace: string, id: string, award: Award) {
        console.log(`client<award>| update(): Enter`);
        console.log(`client<award>| update(): $workspace = ${workspace}`);
        return super.patch("update", workspace, id, document);
    }

    get(workspace: string) {
        console.log(`client<award>| get(): Enter`);
        console.log(`client<award>| get(): $workspace = ${workspace}`);
        return super.get("get", workspace);
    }
}
