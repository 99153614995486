import { UploadFile } from 'antd';
import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FileService } from '../../../../domain/services/file.service';
import useAppContext from '../../use-context.hook';
import { useFileService } from '../file/use-files-service';
import { useAlertContext } from '../../use-alert.hook';
import { SupportDocType } from '../../../../domain/enums/support-doctype.enum';
import { SalesOrderDocument } from '../../../../domain/models/sales-order.model';

export interface UploadDocumentsInterface {
    type: SupportDocType;
    files: UploadFile[];
}

const uploadDocument = async (
    service: FileService,
    workspaceId: string,
    type: SupportDocType,
    files: UploadFile[],
): Promise<SalesOrderDocument[]> => {
    const response = await Promise.all(
        files.map(async (file) => {
            const token = await service.upload(workspaceId, 'salesOrder', {
                filename: file.name,
                contentType: file.type || '',
            });

            await axios.put(token.token, file.originFileObj, {
                headers: {
                    'x-ms-blob-type': 'BlockBlob',
                    'Content-Type': file.type || '',
                },
            });

            return {
                type,
                file: {
                    assetName: token.attachment.assetName,
                    container: 'temporary',
                    contentType: file.type || '',
                    originalName: file.originFileObj?.name,
                },
            } as SalesOrderDocument;
        }),
    );

    return response;
};

/**
 * Upload certification API which will be updated when any of the parameters in the [] array changes
 * Also will set the refresh state to true which will trigger the useEffect hook to fetch the documents
 */
export function useUploadPurchaseFiles() {
    const context = useAppContext();
    const { service } = useFileService();
    const { setAlert } = useAlertContext();
    const client = useQueryClient();

    return useMutation({
        mutationFn: async ({ type, files }: UploadDocumentsInterface) => {
            if (!context.workspace?.id) return;

            return await uploadDocument(
                service,
                context.workspace.id,
                type,
                files,
            );
        },
        onSuccess: () => {
            client.invalidateQueries({
                queryKey: ['companyDocuments', { type: context.workspace?.id }],
            });
        },
        onError: () => {
            setAlert({
                type: 'error',
                message: 'Failed to upload documents',
                title: 'Upload Purchase Documents',
            });
        },
    });
}
