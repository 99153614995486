import { Tag } from '@ianneo/component-library';
import { Card, Divider, Typography } from 'antd';
import { Handle, Position } from 'reactflow';
import './purchase.tracing.node.css';

interface PurchaseTracingNodeLayoutProps {
    data: any;
}

const PurchaseTracingNodeLayout: React.FC<PurchaseTracingNodeLayoutProps> = ({
    data,
}) => {
    const result = data.master ? data.data?.owner : data?.label;
    const count = data?.master ? data.count : data?.label?.manifest?.length;

    const processes = data.master
        ? data.data?.owner.supplier.seller.processes
        : data?.label.supplier.seller?.processes;

    return (
        <>
            <div
                style={{
                    width: '360px',
                }}
            >
                <Handle type="target" position={Position.Left} />

                <Card
                    title={result.externalDataId}
                    style={{ borderRadius: '16px' }}
                    extra={
                        <Typography.Link
                            onClick={() => {
                                data.setSelected({
                                    id: result.id,
                                    workspaceId: data.master
                                        ? result.workspace.id
                                        : result.workspaceId,
                                });
                                data.setOpen(true);
                            }}
                            style={{
                                color: '#972D47',
                            }}
                        >
                            View
                        </Typography.Link>
                    }
                >
                    <div>
                        <Typography>
                            Supplier Name:{' '}
                            {data.master
                                ? result?.supplier?.seller?.name
                                : data?.label?.supplier?.seller?.name}
                        </Typography>
                    </div>
                    <div>
                        <Typography>Product Count: {count}</Typography>
                    </div>
                    <div>
                        <Typography>
                            {processes.map((x: any) => (
                                <Tag>{x}</Tag>
                            ))}
                        </Typography>
                    </div>

                    {result?.supplierProducer ? (
                        <>
                            <Divider type="horizontal" />
                            <Typography>
                                Secondary Supplier Name:{' '}
                                {result.supplierProducer.companyName}
                            </Typography>

                            <Typography>
                                {result.supplierProducer.processes.map(
                                    (x: any) => (
                                        <Tag>{x}</Tag>
                                    ),
                                )}
                            </Typography>
                        </>
                    ) : null}
                </Card>

                <Handle type="source" position={Position.Right} />
            </div>
        </>
    );
};

export default PurchaseTracingNodeLayout;
