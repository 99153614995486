import { PurchaseOperatorClient } from '../../infrastructure/clients/purchase-operator.client';
import { ServiceConfigInterface } from '../interfaces/config.context.interface';

export class PurchaseOperatorService {
    private server: PurchaseOperatorClient;

    constructor(
        config: ServiceConfigInterface,
        private readonly token: string,
    ) {
        this.server = new PurchaseOperatorClient(config, token);
    }

    async get(workspace: string, searchTerm: string) {
        console.log(`service<purchase-operator>| get(): Enter`);
        console.log(
            `service<purchase-operator>| get(): $workspace = ${workspace}`,
        );
        console.log(
            `service<purchase-operator>| get(): $searchTerm = ${searchTerm}`,
        );

        return await this.server.get(workspace, searchTerm);
    }
}
