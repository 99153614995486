import { InfoCircleOutlined } from '@ant-design/icons';
import {
    Card,
    InfoCard,
    Popover,
    RadioGroup,
    Title,
    Typography,
} from '@ianneo/component-library';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { usePurchase } from '../../../infrastructure/hooks/api/purchases/use-purchase';
import { usePurchaseTrace } from '../../../infrastructure/hooks/api/purchases/use-purchase-trace';
import OrderCascadeTrace from './order-cascade-trace';
import OrderDocuments from './order-documents';
import OrderMonitor from './order-monitor';
import OrderOverview from './order-overview';

export default function OrderDetails() {
    const { t } = useTranslation();
    const { id, workspaceId } = useParams();
    const { data } = usePurchase({ id, customWorkspaceId: workspaceId });
    const { data: trace } = usePurchaseTrace({ id });

    const [menu, setMenu] = useState<
        'overview' | 'cascade_tracing' | 'documents' | 'monitor' | 'track_trace'
    >('overview');
    const menuOptions = [
        { label: t('purchase:detail.menu.overview'), value: 'overview' },
        {
            label: t('purchase:detail.menu.cascadeTracing'),
            value: 'cascade_tracing',
        },
        { label: t('purchase:detail.menu.documents'), value: 'documents' },
        { label: t('purchase:detail.menu.monitor'), value: 'monitor' },
    ];

    const content = (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography>
                <Trans i18nKey={'purchase:deliveredStatus'}></Trans>
            </Typography>

            <Typography>
                <Trans i18nKey={'purchase:pendingStatus'}></Trans>
            </Typography>
        </div>
    );

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '36px',
                }}
            >
                <Title description={t('purchase:description') || ''}>
                    {t('purchase:name')}
                </Title>

                <InfoCard
                    infoTitle={data?.owner?.externalDataId}
                    information={
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '12px',
                                }}
                            >
                                <Typography>{data?.owner?.status}</Typography>
                                <Popover
                                    trigger="hover"
                                    content={content}
                                    placement="right"
                                    overlayInnerStyle={{ padding: '0px' }}
                                >
                                    <InfoCircleOutlined
                                        disabled
                                        style={{ color: 'grey' }}
                                    />
                                </Popover>
                            </div>
                        </>
                    }
                />

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '24px',
                    }}
                >
                    <RadioGroup
                        items={menuOptions}
                        defaultValue={menu}
                        onChange={(e) => setMenu(e.target.value)}
                    />

                    <Card>
                        {menu === 'overview' ? (
                            <OrderOverview
                                data={data || {}}
                                trace={trace || []}
                            />
                        ) : null}

                        {menu === 'cascade_tracing' ? (
                            <OrderCascadeTrace />
                        ) : null}

                        {menu === 'documents' ? (
                            <OrderDocuments data={data || {}} />
                        ) : null}

                        {menu === 'monitor' ? (
                            <OrderMonitor data={data || {}} />
                        ) : null}
                    </Card>
                </div>
            </div>
        </>
    );
}
