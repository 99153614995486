import { Auth0Provider } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AppRouter } from './router/app.router';

import './App.css';
import 'reactflow/dist/style.css';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import AppContextProvider from '../infrastructure/providers/context.provider';
import './i18n';

const client = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 5 * 60 * 1000,
        },
    },
});

function App() {
    return (
        <Auth0Provider
            audience={process.env.REACT_APP_AUTH0_AUDIENCE ?? 't4s_app'}
            domain={process.env.REACT_APP_AUTH0_DOMAIN ?? 'jwan.us.auth0.com'}
            clientId={
                process.env.REACT_APP_AUTH0_CLIENT ??
                'eUvGTdXNooKJ3LffyLrYqLagvZ0rpgq6'
            }
            redirectUri={window.location.origin}
        >
            <QueryClientProvider client={client}>
                <AppContextProvider>
                    <AppRouter />
                </AppContextProvider>

                <ReactQueryDevtools />
            </QueryClientProvider>
        </Auth0Provider>
    );
}

export default App;
