import { useAuth0 } from '@auth0/auth0-react';
import {
    useCallback,
    useContext,
    useEffect,
    useLayoutEffect,
    useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from '../../domain/models/app.context.model';
import { Workspace } from '../../domain/models/workspace.model';
import { MeService } from '../../domain/services/me.service';
import useAppConfig from './use-config.hook';

const useT4SAuth = () => {
    const auth = useAuth0();
    const config = useAppConfig();
    const context = useContext(AppContext);
    const navigate = useNavigate();

    const [isExpired, setIsExpired] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [isOperator, setIsOperator] = useState(false);

    const validateClaimExpiration = useCallback(async () => {
        const claims = await auth.getIdTokenClaims();
        if (!claims || !claims.exp) return;

        const { exp } = claims;
        const now = new Date().getTime() / 1000;
        const expired = exp < now;

        if (expired) {
            auth.logout({ returnTo: window.location.origin });
        }

        setIsExpired(expired);
    }, [auth]);

    // This will only run once the auth is confirmed to be authenticated
    const validateCompanyFields = useCallback(async (workspace: Workspace) => {
        if (!workspace || !workspace.company) return;

        const { company } = workspace;

        // These are the mandatory fields that are currently set.
        const mandatoryFields = [
            'name',
            'country',
            'registrationNumber',
            'supplyChainNodeType',
            'phone',
            'addressLine1',
            'introduction',
            'city',
            'state',
            'postCode',
        ];

        // This will filter out if any of the company fields that are mandatory is empty.
        const missingFields = mandatoryFields.filter(
            (field) => !company[field],
        );

        setIsCompleted(missingFields.length === 0);
    }, []);

    const updateAuthContext = useCallback(async () => {
        if (isExpired) return;

        const token = await auth.getAccessTokenSilently();

        const operatorReference =
            process.env.REACT_APP_OPERATOR_REFERENCE ??
            '41bf4acc-1146-42c8-a315-6a99afb32806';

        const service = new MeService(config, token);
        const profile = await service.setup(operatorReference);

        if (profile?.workspace?.id !== operatorReference) {
            setIsOperator(false);
            setIsCompleted(true);

            return;
        }

        // Setting the initial states of the context.
        context.token = token;
        context.user = context.user ?? profile.user;
        context.workspace = context.workspace ?? profile.workspace;
        context.permissions = context.permissions ?? profile.permissions;

        // Will fetch all existing workspace options and switch to the one that is selected
        if (localStorage.getItem('search')) {
            const workspaceId = Object.fromEntries(
                new URLSearchParams(localStorage.getItem('search')!),
            ).w;
            const workspaces = await service.getWorkspaceOptions();
            const switchedWorkspace = workspaces?.find(
                (w) => w?.id === workspaceId,
            );

            if (switchedWorkspace) {
                const switchedProfile = await service.switch(switchedWorkspace);

                context.user = switchedProfile.user;
                context.workspace = switchedProfile.workspace;
                context.permissions = switchedProfile.permissions;
            }

            localStorage.removeItem('search');
        }

        if (!context.workspace) return;

        await validateCompanyFields(context.workspace);
        setIsOperator(true);

        setIsReady(true);
    }, [auth, isExpired, config, context, validateCompanyFields]);

    const navigateToRedirectUrl = useCallback(() => {
        if (localStorage.getItem('redirectUrl')) {
            const isSwitchWorkspace = localStorage.getItem('isSwitchWorkspace');
            const redirectUrl = isSwitchWorkspace
                ? localStorage.getItem('redirectUrl')?.split('/')[0]
                : localStorage.getItem('redirectUrl');

            localStorage.removeItem('redirectUrl');
            localStorage.removeItem('isSwitchWorkspace');

            navigate(`/${redirectUrl}`);
        }
    }, [navigate]);

    useLayoutEffect(() => {
        if (auth.isAuthenticated) {
            setIsAuthenticated(true);
        }
    }, [auth.isAuthenticated]);

    // This will only run once the auth is confirmed to be authenticated
    useLayoutEffect(() => {
        if (!isAuthenticated) return;

        validateClaimExpiration();
        updateAuthContext();
    }, [isAuthenticated, validateClaimExpiration, updateAuthContext]);

    // This is to get the current path before running the auth flow
    useEffect(() => {
        const path = window.location.pathname;
        const params = window.location.search;

        const redirectUrl = path.substring(path.indexOf('/') + 1);
        if (redirectUrl.startsWith('?code=') || !redirectUrl) return;

        localStorage.setItem('redirectUrl', redirectUrl);
        localStorage.setItem('search', params.toString());
    }, []);

    useEffect(() => {
        if (!isReady || !isCompleted) return;

        navigateToRedirectUrl();
    }, [isReady, isCompleted, navigateToRedirectUrl]);

    return { isExpired, isAuthenticated, isReady, isCompleted, isOperator };
};

export default useT4SAuth;
