import { useQuery } from '@tanstack/react-query';
import { WorkspaceService } from '../../../../domain/services/workspace.service';
import { useAlertContext } from '../../use-alert.hook';
import { useWorkspaceService } from './use-workspace-service';
import { Workspace } from '../../../../domain/models/workspace.model';

interface UseWorkspaceInterface {
    id: string;
}

const getWorkspace = async (
    service: WorkspaceService,
    id: string,
): Promise<Workspace> => {
    const response = await service.get(id);

    return response;
};

export function useWorkspace({ id }: UseWorkspaceInterface) {
    const { service } = useWorkspaceService();
    const { setAlert } = useAlertContext();

    return useQuery(['workspace', id], async () => {
        try {
            const response = await getWorkspace(service, id);

            return response;
        } catch (err) {
            setAlert({
                type: 'error',
                message: 'Failed to fetch workspace information',
            });
        }
    });
}
