interface PDFViewerProps {
    pdfUrl: string;
    style?: React.CSSProperties;
    loaded?: boolean;
}

export function PDFViewer({ pdfUrl, style, loaded }: PDFViewerProps) {
    return (
        <iframe
            src={pdfUrl}
            sandbox={loaded ? 'allow-same-origin' : undefined}
            style={style}
            title="PDF Viewer"
            width="100%"
            height="100%"
        ></iframe>
    );
}
