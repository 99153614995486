import React, { useCallback, useState } from 'react';

import { ResourceName } from '../../domain/types/resource-name.type';
import AppContext from '../../domain/models/app.context.model';
import { AppContextCache } from '../../domain/interfaces/app.context.interface';
import { PermissionScope } from '../../domain/types/permission-scope.type';
import { useAuth0 } from '@auth0/auth0-react';

interface AppContextProviderProps {
    initAppContextCache?: AppContextCache;
    children: any;
}

const AppContextProvider: React.FC<AppContextProviderProps> = ({
    initAppContextCache,
    children,
}) => {
    const auth0 = useAuth0();
    console.log(
        `provide<context>: $isAuthenticated = ${JSON.stringify(
            auth0.isAuthenticated,
        )}`,
    );
    console.log(`provide<context>: $user = ${JSON.stringify(auth0.user)}`);

    const [appContextCache, setAppContextCache] = useState<AppContextCache>(
        initAppContextCache || {
            locale: 'en',
        },
    );

    const handleAppContextCacheItemUpdate = useCallback(
        <K extends keyof AppContextCache>(
            cacheItem: K,
            itemValue: AppContextCache[K],
        ) => {
            console.log(
                `provide<context>: $cacheItem: ${JSON.stringify(cacheItem)}`,
            );
            console.log(
                `provide<context>: $itemValue: ${JSON.stringify(itemValue)}`,
            );
            setAppContextCache((prevState) => ({
                ...prevState,
                [cacheItem]: itemValue,
            }));
        },
        [],
    );

    //   const hasItemTypeConfigRead = () => {
    //     return !!(
    //       appContextCache.permissions &&
    //       appContextCache.permissions.find(
    //         (permssion) =>
    //           permssion.module === "settings" &&
    //           permssion.action.includes("read")
    //       )
    //     );
    //   };

    const hasRight: PermissionScope = (
        resource: ResourceName,
        action: string,
    ) => {
        if (!appContextCache.permissions) {
            return false;
        } else {
            console.log(appContextCache);
            if (
                appContextCache.permissions.find(
                    (permission) =>
                        permission.module === resource &&
                        permission.action === action,
                )
            ) {
                return true;
            }
            return false;
        }
    };

    return (
        <AppContext.Provider
            value={{
                ...appContextCache,
                onAppContextCacheUpdate: setAppContextCache,
                onAppContextCacheUpdated: handleAppContextCacheItemUpdate,
                hasRight,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;
