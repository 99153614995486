import { useQuery } from '@tanstack/react-query';
import { WorkspaceOperatorService } from '../../../../domain/services/workspace-operator.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useWorkspaceOperatorService } from './use-workspace-operator-service';
import { Workspace } from '../../../../domain/models/workspace.model';
import { AxiosError } from 'axios';

interface UseSearchSuppliersInterface {
    searchTerm?: string;
}

async function search(
    service: WorkspaceOperatorService,
    workspace: string,
    term: string,
): Promise<Workspace[]> {
    const response = await service.get(workspace, term);

    return response;
}

export function useSearchSuppliers({
    searchTerm,
}: UseSearchSuppliersInterface) {
    const context = useAppContext();
    const { service } = useWorkspaceOperatorService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: [
            'search-workspaces',
            { workspace: context.workspace?.id, term: searchTerm },
        ],
        queryFn: async () => {
            if (!context.workspace?.id || !searchTerm) return [];

            return await search(
                service,
                context.workspace.id,
                searchTerm || '',
            );
        },
        enabled: false,
        retry: false,
        onError: (err) => {
            if (err instanceof AxiosError) {
                if (err.response?.status === 403) {
                    setAlert({
                        type: 'error',
                        title: 'Access Denied',
                        message: 'You are not authorised to view this page',
                    });

                    return;
                }
            }

            setAlert({
                type: 'error',
                title: 'Internal server error',
                message: 'Failed to fetch supplier information',
            });
        },
    });
}
