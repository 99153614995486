export enum PurchaseOrderStatus {
    CANCELLED = 'CANCELLED',
    CLOSED = 'CLOSED',
    DELIVERED = 'DELIVERED',
    DETAINED = 'DETAINED',
    DISCARDED = 'DISCARDED',
    DRAFT = 'DRAFT',
    RELEASED = 'RELEASED',
    SETTLED = 'SETTLED',
    WITHHELD = 'WITHHEAD',
}
