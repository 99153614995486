import { DownloadOutlined } from '@ant-design/icons';
import {
    Button,
    Card,
    Link,
    Modal,
    Select,
    Table,
    Typography,
    UploadAddTable,
    UploadFile,
    useForm,
} from '@ianneo/component-library';
import { DatePicker } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Award } from '../../../domain/models/award.modal';
import { useAwards } from '../../../infrastructure/hooks/api/award/use-awards';
import { useAwardTypes } from '../../../infrastructure/hooks/api/award/use-awards-types';
import useDownloadAward from '../../../infrastructure/hooks/api/award/use-download-award';
import useDownloadAwards from '../../../infrastructure/hooks/api/award/use-download-awards';
import { useUploadAward } from '../../../infrastructure/hooks/api/award/use-upload-award';
import { useParams } from 'react-router-dom';

export default function SupplierCertifications() {
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const { data } = useAwards(id);
    const { data: types } = useAwardTypes();
    const [open, setOpen] = useState(false);
    const [form] = useForm();
    const [files, setFiles] = useState<UploadFile<any>[]>([]);
    const [selectedRows, setSelectedRows] = useState<Award[]>([]);

    const downloadCert = useDownloadAward();
    const downloadCerts = useDownloadAwards();
    const { mutateAsync } = useUploadAward();

    const rowSelection = {
        selectedRows,
        onChange: (_: React.Key[], selected: any) => {
            setSelectedRows(selected);
        },
    };

    const onDownload = () => {
        downloadCerts({ awards: selectedRows });
    };

    const columns = useMemo(
        () => [
            {
                title: t('company:certifications.certName'),
                dataIndex: ['certification', 'name'],
            },
            {
                title: t('company:certifications.type'),
                dataIndex: ['certification', 'certificationCode'],
            },
            {
                title: t('company:certifications.standard'),
                dataIndex: ['certification', 'certifyBody'],
            },
            { title: t('company:certifications.issuedBy'), dataIndex: '' },
            {
                title: t('company:certifications.status'),
                dataIndex: 'verifiedOn',
                render: (_: string, record: Award) => (
                    <Typography>
                        {record.verifiedOn ? 'Verified' : 'Not Verified'}
                    </Typography>
                ),
            },
            {
                title: t('company:certifications.issuedDate'),
                dataIndex: 'issuedDate',
                render: (_: string, record: Award) => {
                    return (
                        <Typography>
                            {record.issuedDate
                                ? new Date(record.issuedDate)
                                      .toISOString()
                                      .slice(0, 10)
                                : ''}
                        </Typography>
                    );
                },
            },
            {
                title: t('company:certifications.expiryDate'),
                dataIndex: 'expiryDate',
                render: (_: string, record: Award) => {
                    return (
                        <Typography>
                            {record.expiryDate
                                ? new Date(record.expiryDate)
                                      .toISOString()
                                      .slice(0, 10)
                                : ''}
                        </Typography>
                    );
                },
            },
            { title: t('company:certifications.docNo'), dataIndex: '' },
            {
                title: t('company:certifications.certNo'),
                dataIndex: 'certificateNumber',
            },
            {
                title: t('common:updatedOn'),
                dataIndex: 'lastUpdatedOn',
                render: (_: string, record: Award) => {
                    return (
                        <Typography>
                            {record.lastUpdatedOn
                                ? new Date(record.lastUpdatedOn)
                                      .toISOString()
                                      .slice(0, 10)
                                : ''}
                        </Typography>
                    );
                },
            },
            {
                title: t('common:actions'),
                render: (_: any, item: Award) => (
                    <Link onClick={() => downloadCert({ award: item })}>
                        {t('common:download')}
                    </Link>
                ),
            },
        ],
        [t, downloadCert],
    );

    const uploadColumns = useMemo(
        () => [
            {
                title: t('company:certifications.certName'),
                dataIndex: ['documentation', 'assetName'],
            },
            {
                title: t('company:certifications.auditOrg'),
                dataIndex: 'auditOrganization',
            },
            {
                title: t('company:certifications.certification'),
                dataIndex: ['certification', 'id'],
                component: (
                    <Select
                        options={Object.values(types || {}).map((x) => ({
                            label: x.name,
                            value: x.id,
                            key: x.id,
                        }))}
                    />
                ),
            },
            {
                title: t('company:certifications.certNo'),
                dataIndex: 'certificateNumber',
            },
            {
                title: t('company:certifications.expiryDate'),
                dataIndex: 'expiryDate',
                component: <DatePicker />,
            },
            {
                title: t('company:certifications.issuedDate'),
                dataIndex: 'issuedDate',
                component: <DatePicker />,
            },
        ],
        [t, types],
    );

    const onUpload = (files: UploadFile<any>[]) => {
        setFiles(files);

        const values = form.getFieldsValue(true);
        const items = values.items ? values.items : [];

        const newItems: any[] = [];
        files.forEach((x) => {
            const item = items.find((y: any) => y.id === x.uid);
            if (item) return;

            const record: Award = {
                id: x.uid,
                certification: { id: types?.[0]?.id },
                documentation: {
                    assetName: x.name,
                    container: 'temporary',
                    contentType: x.type || '',
                    originalName: x.name,
                    autoResign: false,
                },
            };

            newItems.push(record);
        });

        values.items = [...items, ...newItems];
    };

    const reset = () => {
        setOpen(false);
        setFiles([]);
        form.resetFields();
    };

    return (
        <>
            <Card>
                <Table
                    columns={columns}
                    dataSource={data}
                    rowKey="id"
                    displaySearch
                    rowSelection={rowSelection}
                    actionContent={
                        <>
                            <Button
                                mode="create"
                                onClick={async () => onDownload()}
                            >
                                <DownloadOutlined />
                                {t('common:download')}
                            </Button>

                            {/* <Button mode="create" onClick={() => setOpen(true)}>
                                <PlusOutlined />
                                {t('common:addNew')}
                            </Button> */}
                        </>
                    }
                />
            </Card>

            <Modal
                title="Upload Document"
                open={open}
                width={1000}
                closable={false}
                footerChildren={
                    <>
                        <Button
                            mode="comment-hollow"
                            onClick={() => {
                                reset();
                            }}
                        >
                            {t('common:cancel')}
                        </Button>
                        <Button
                            mode="comment"
                            onClick={async () => {
                                const values = form.getFieldsValue(true);
                                await mutateAsync({
                                    awards: values.items,
                                    files,
                                });

                                reset();
                            }}
                        >
                            {t('common:upload')}
                        </Button>
                    </>
                }
            >
                <UploadAddTable
                    form={form}
                    files={files}
                    setFiles={onUpload}
                    columns={uploadColumns}
                />
            </Modal>
        </>
    );
}
