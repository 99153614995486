import { useQuery } from '@tanstack/react-query';
import { SalesOrder } from '../../../../domain/models/sales-order.model';
import { OrderService } from '../../../../domain/services/order.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useOrderService } from './use-order-service';

interface UseOrderInterface {
    id?: string;
}

const getOrder = async (
    service: OrderService,
    workspace: string,
    id: string,
): Promise<SalesOrder> => {
    const response = await service.getPo(workspace, id);

    return response;
};

export function useOrder(args: UseOrderInterface) {
    const context = useAppContext();
    const { service } = useOrderService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: ['order', { workspace: context.workspace?.id, id: args.id }],
        queryFn: async () => {
            if (!context.workspace?.id || !args.id) return {};

            return await getOrder(service, context.workspace.id, args.id);
        },
        onError: (err) => {
            setAlert({
                type: 'error',
                message: 'Failed to fetch order information',
            });
        },
    });
}
