import PurchaseTracingNodeLayout from './purchase.tracing.node.layout';

interface PurchaseTracingNodeContainerProps {
    data: any;
}

const PurchaseTracingNodeContainer: React.FC<
    PurchaseTracingNodeContainerProps
> = ({ data }) => {
    return <PurchaseTracingNodeLayout data={data} />;
};

export default PurchaseTracingNodeContainer;
