import { NotificationApiClient } from '../../infrastructure/clients/notification.client';
import { ServiceConfigInterface } from '../interfaces/config.context.interface';
import { NotificationMessage } from '../models/notification-message.model';

export class NotificationService {
    private server: NotificationApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new NotificationApiClient(config, token);
    }

    async list(workspace: string): Promise<NotificationMessage[]> {
        console.log(`service<notification>| list(): Enter`);
        console.log(`service<notification>| list(): $workspace = ${workspace}`);

        return await this.server.list(workspace);
    }

    async mark(workspace: string, id: number): Promise<NotificationMessage> {
        console.log(`service<notification>| mark(): Enter`);
        console.log(`service<notification>| mark(): $workspace = ${workspace}`);
        console.log(`service<notification>| mark(): $id = ${id}`);

        return await this.server.mark(workspace, id);
    }
}
