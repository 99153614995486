import { CompanyDocumentApiClient } from '../../infrastructure/clients/document.client';
import { ServiceConfigInterface } from '../interfaces/config.context.interface';
import { CompanyDocument } from '../models/company-doc.model';

/**
 * @class
 * @name CompanyDocumentService
 * @description The company document service to load and get docs
 * @author Mark Leung <markleungcl@lfxdigital.com>
 */
export class CompanyDocumentService {
    private server: CompanyDocumentApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new CompanyDocumentApiClient(config, token);
    }

    private convert(source: any) {
        console.log(`service<company-doc>| convert(): Enter`);
        console.log(
            `service<company-doc>| convert(): $source = ${JSON.stringify(
                source,
            )}`,
        );
        const result: CompanyDocument = {
            id: source.id,
            comment: source.comment,
            file: source.file,
            lastUpdatedOn: source.lastUpdatedOn,
            nature: source.nature,
            status: source.status,
            verifiedOn: source.verifiedOn,
        };
        return result;
    }

    async create(workspace: string, document: CompanyDocument) {
        console.log(`service<company-doc>| create(): Enter`);
        console.log(
            `service<company-doc>| create(): $workspace = ${workspace}`,
        );
        console.log(
            `service<company-doc>| create(): $document = ${JSON.stringify(
                document,
            )}`,
        );

        return this.convert(await this.server.create(workspace, document));
    }

    async list(workspace: string, operator: string) {
        console.log(`service<company-doc>| list(): Enter`);
        console.log(`service<company-doc>| list(): $workspace = ${workspace}`);
        const result: any[] = await this.server.list(workspace, operator);
        return result.map((r) => this.convert(r));
    }

    async update(workspace: string, item: string, document: CompanyDocument) {
        console.log(`service<company-doc>| create(): Enter`);
        console.log(
            `service<company-doc>| create(): $workspace = ${workspace}`,
        );
        console.log(
            `service<company-doc>| create(): $workspace = ${JSON.stringify(
                document,
            )}`,
        );
        const existing = await this.server.get(workspace, item);
        if (!existing) throw new Error(`Document requested is not defined`);
        return this.convert(
            await this.server.update(workspace, item, document),
        );
    }
}
