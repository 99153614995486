import { useMemo } from 'react';
import useAppConfig from '../../use-config.hook';
import useAppContext from '../../use-context.hook';
import { WorkspaceOperatorService } from '../../../../domain/services/workspace-operator.service';

export function useWorkspaceOperatorService() {
    const config = useAppConfig();
    const context = useAppContext();

    const service = useMemo(() => {
        if (context.token) {
            return new WorkspaceOperatorService(config, context.token);
        } else {
            throw new Error('Session Expired');
        }
    }, [config, context.token]);

    return { service };
}
