import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UpdateVerificationParams } from '../../../../domain/models/verification.model';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useVerificationService } from './use-verification-service';

export interface UseUpdateVerificationParams {
    id: string;
    params: UpdateVerificationParams;
}

export function useUpdateVerification() {
    const context = useAppContext();
    const client = useQueryClient();
    const { service } = useVerificationService();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async ({ id, params }: UseUpdateVerificationParams) => {
            if (!context.workspace?.id || !id) return;
            await service.put(context.workspace.id, id, params);
        },
        onSuccess: (_, args) => {
            client.invalidateQueries({
                queryKey: [
                    'verification',
                    { workspace: context.workspace?.id, id: args.id },
                ],
            });

            setAlert({
                type: 'success',
                message: 'Verification information updated successfully',
                title: 'Update Verification',
            });
        },
        onError: () => {
            setAlert({
                type: 'error',
                message: 'Failed to update verification information',
                title: 'Update Verification',
            });
        },
    });
}
