import './loading.page.css'
import { Spin } from 'antd';
export interface LoadPageProps {
    pending?: boolean
};

const Loading: React.FC<LoadPageProps> = ({
    pending
}) => {
    return (
        <div className='loading'>
            <Spin size='large' tip={pending === true ? "Setting up your workspace" : "T4S is starting"} />
        </div>
    )
};

export default Loading;