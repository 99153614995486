
import React from "react";
import { AlertMessage } from "../../domain/interfaces/alert.interface"

export type AlertContextType = {
    alert?: AlertMessage | undefined;
    setAlert: (alert: AlertMessage | undefined) => void;
}

export const AlertContext = React.createContext<AlertContextType>({ 
    alert: undefined,
    setAlert: (alert: AlertMessage | undefined) => {
        console.log(`setting Alert Data ${alert}`);
    }
 });