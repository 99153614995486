import { TaskApiClient } from '../../infrastructure/clients/task.client';
import { ServiceConfigInterface } from '../interfaces/config.context.interface';
import { CreateTaskModel, Task } from '../models/task.model';

export class TaskService {
    private server: TaskApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new TaskApiClient(config, token);
    }

    async get(workspace: string): Promise<Task[]> {
        console.log(`service<task>| get(): Enter`);
        console.log(`service<task>| get(): $workspace = ${workspace}`);

        return (await this.server.get(workspace)) as Task[];
    }

    async create(
        workspace: string,
        userId: string,
        task: CreateTaskModel,
    ): Promise<Task> {
        console.log(`service<task>| create(): Enter`);
        console.log(`service<task>| create(): $workspace = ${workspace}`);
        console.log(`service<task>| create(): $userId = ${userId}`);

        return (await this.server.create(workspace, userId, task)) as Task;
    }

    async delete(id: string) {
        console.log(`service<task>| delete(): Enter`);
        console.log(`service<task>| delete(): $id = ${id}`);

        return await this.server.delete(id);
    }
}
