import { PurchaseOrderStatus } from '../../domain/enums/purchase-order-status.enum';

/**
 * @function getPOStatus
 * @param value {PurchaseOrderStatus} this is value we submit to translate
 * @returns {string}
 */
export const getPOStatus = (value: PurchaseOrderStatus | undefined) => {
    switch (value) {
        case PurchaseOrderStatus.CANCELLED:
            return 'Cancelled';
        case PurchaseOrderStatus.CLOSED:
            return 'Closed';
        case PurchaseOrderStatus.DELIVERED:
            return 'Delivered';
        case PurchaseOrderStatus.DETAINED:
            return 'Detained';
        case PurchaseOrderStatus.DISCARDED:
            return 'Discarded';
        case PurchaseOrderStatus.DRAFT:
            return 'Draft';
        case PurchaseOrderStatus.RELEASED:
            return 'Released';
        case PurchaseOrderStatus.SETTLED:
            return 'Settled';
        case PurchaseOrderStatus.WITHHELD:
            return 'Withheld';
        default:
            return 'N/A';
    }
};
