import { useQuery } from '@tanstack/react-query';
import { PurchaseDocumentTrace } from '../../../../domain/models/purchase.model';
import { OrderService } from '../../../../domain/services/order.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useOrderService } from './use-order-service';

interface UseOrderSearchInterface {
    ids: number[];
}

const getOrder = async (
    service: OrderService,
    ids: number[],
): Promise<PurchaseDocumentTrace[]> => {
    const response = await service.search(ids);

    return response;
};

export function useOrderSearch(args: UseOrderSearchInterface) {
    const context = useAppContext();
    const { service } = useOrderService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: [
            'orderSearch',
            { workspace: context.workspace?.id, ids: args.ids },
        ],
        queryFn: async () => {
            if (!context.workspace?.id || !(args.ids.length > 0)) return [];

            return await getOrder(service, args.ids);
        },
        onError: (err) => {
            setAlert({
                type: 'error',
                message: 'Failed to fetch order search information',
            });
        },
    });
}
