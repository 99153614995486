import { ServiceConfigInterface } from '../../domain/interfaces/config.context.interface';
import { User } from '../../domain/models/user.model';
import { ApiServer } from './api.server';

/**
 * @class
 * @name MeClient
 * @description the API client for the user self endpoint
 * @author Mark Leung <markleungcl@lfxdigital.com>
 */
export class MeClient extends ApiServer<any> {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'me');
    }

    async get(): Promise<User> {
        console.log(`servce<me>| get(): Enter`);
        return super.get(['get']);
    }

    async getWorkspaces() {
        console.log(`servce<me>| getWorkspaces(): Enter`);
        return super.get(['myWorkspace']);
    }

    async getAllowed(workspace: string): Promise<string[]> {
        console.log(`servce<me>| getAllowed(): Enter`);
        console.log(`servce<me>| getAllowed(): $workspace = ${workspace}`);
        return super.get('myPermissions', workspace);
    }

    async inviteUser(workspace: string, params: any) {
        console.log(`servce<me>| inviteUser(): Enter`);
        console.log(`servce<me>| inviteUser(): $workspace = ${workspace}`);
        console.log(`servce<me>| inviteUser(): $params = ${params}`);

        return super.post('invite', workspace, params);
    }
}
