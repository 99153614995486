import { useQuery } from '@tanstack/react-query';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { usePurchaseOperatorService } from './use-purchase-operator-service';
import { PurchaseOperatorService } from '../../../../domain/services/purchase-operator.service';
import { PurchaseOrder } from '../../../../domain/models/purchase.model';
import { AxiosError } from 'axios';

interface UseSearchPurchasesInterface {
    searchTerm?: string;
}

async function search(
    service: PurchaseOperatorService,
    workspace: string,
    term: string,
): Promise<PurchaseOrder[]> {
    const response = await service.get(workspace, term);

    return response;
}

export function useSearchPurchases({
    searchTerm,
}: UseSearchPurchasesInterface) {
    const context = useAppContext();
    const { service } = usePurchaseOperatorService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: [
            'search-purchases',
            { workspace: context.workspace?.id, term: searchTerm },
        ],
        queryFn: async () => {
            if (!context.workspace?.id || !searchTerm) return [];

            return await search(
                service,
                context.workspace.id,
                searchTerm || '',
            );
        },
        onError: (err) => {
            if (err instanceof AxiosError) {
                if (err.response?.status === 403) {
                    setAlert({
                        type: 'error',
                        title: 'Access Denied',
                        message: 'You are not authorised to view this page',
                    });
                    return;
                }
            }

            setAlert({
                type: 'error',
                title: 'Internal server error',
                message: 'Failed to fetch purchase information',
            });
        },
        retry: false,
        enabled: false,
    });
}
