import { useQuery } from '@tanstack/react-query';
import { PurchaseOrderVersion } from '../../../../domain/models/purchase.model';
import { PurchaseService } from '../../../../domain/services/purchase.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { usePurchaseService } from './use-purchase-service';

interface UsePurchaseInterface {
    id?: string;
    customWorkspaceId?: string;
}

const getPurchase = async (
    service: PurchaseService,
    workspace: string,
    id: string,
    customId?: string,
): Promise<PurchaseOrderVersion> => {
    const response = await service.get(
        customId ? customId : workspace,
        id,
        '1',
    );

    return response;
};

export function usePurchase(args: UsePurchaseInterface) {
    const context = useAppContext();
    const { service } = usePurchaseService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: [
            'purchase',
            { workspace: context.workspace?.id, id: args.id },
        ],
        queryFn: async () => {
            if (!context.workspace?.id || !args.id) return {};

            return await getPurchase(
                service,
                context.workspace.id,
                args.id,
                args.customWorkspaceId,
            );
        },
        onError: (err) => {
            setAlert({
                type: 'error',
                message: 'Failed to fetch purchase information',
            });
        },
    });
}
