import { SearchOutlined } from '@ant-design/icons';
import {
    Input,
    Link,
    Table,
    Tag,
    Title,
    Typography,
} from '@ianneo/component-library';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchSuppliers } from '../../../infrastructure/hooks/api/operator/use-search-suppliers';
import { useNavigate } from 'react-router-dom';
import { Workspace } from '../../../domain/models/workspace.model';

export default function Suppliers() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);

    const { data, refetch, isFetching } = useSearchSuppliers({
        searchTerm: search,
    });

    const onHandleSearch = async () => {
        await refetch();
    };

    useEffect(() => {
        setLoading(isFetching);
    }, [isFetching]);

    useEffect(() => {
        console.log('data');
        console.log(data);
    }, [data]);

    const columns = useMemo(
        () => [
            {
                title: t('workspace:listing.companyName'),
                dataIndex: ['company', 'name'],
                render: (_: any, item: Workspace) => (
                    <>
                        <Link onClick={() => navigate(`/suppliers/${item.id}`)}>
                            {item.company?.name}
                        </Link>
                    </>
                ),
            },
            {
                title: t('workspace:listing.displayName'),
                dataIndex: ['displayName'],
            },
            {
                title: t('workspace:listing.operations'),
                dataIndex: 'operations',
                render: (_: any, item: Workspace) => (
                    <>
                        {item.company?.supplyChainNodeType?.map((x) => (
                            <Tag>{x}</Tag>
                        ))}
                    </>
                ),
            },
        ],
        [t, navigate],
    );

    return (
        <>
            <div style={{ marginBottom: '24px' }}>
                <Title description={t('supplier:description') || ''}>
                    {t('supplier:title')}
                </Title>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '12px',
                        alignItems: 'center',
                    }}
                >
                    <Typography>{t('common:search.label')}: </Typography>
                    <Input
                        style={{ width: '20%' }}
                        value={search}
                        onChange={(event) => setSearch(event.target.value)}
                        placeholder={t('common:search.placeholder') || ''}
                        suffix={<SearchOutlined onClick={onHandleSearch} />}
                        onPressEnter={onHandleSearch}
                    />
                </div>

                <Table columns={columns} dataSource={data} loading={loading} />
            </div>
        </>
    );
}
