import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ga4 from 'react-ga4';

const trackingId = 'G-55TJ461BCJ';
const isProduction = process.env.NODE_ENV === 'production';

const init = () => ga4.initialize(trackingId, { testMode: !isProduction });

const sendPageview = (path: string) =>
    ga4.send({
        hitType: 'pageview',
        page: path,
    });

export function useGoogleAnalyticsTracing() {
    const location = useLocation();

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        const path = location.pathname + location.search;
        sendPageview(path);
    }, [location]);
}
