import {
    PurchaseOrder,
    PurchaseOrderTrace,
    PurchaseOrderVersion,
} from './../models/purchase.model';
import { PurchaseApiClient } from '../../infrastructure/clients/purchase.client';
import { ServiceConfigInterface } from '../interfaces/config.context.interface';
import { ProductMaterial } from '../models/material.model';
import { PurchaseOrderUpdateDataObject } from '../../infrastructure/hooks/api/purchases/use-update-purchase';

/**
 * @class
 * @name PurchaseService
 * @description The Purchase service for LFX
 * @author Ian Neo <ian.neo@ecloudvalley.com>
 */
export class PurchaseService {
    private server: PurchaseApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new PurchaseApiClient(config, token);
    }

    async create(workspace: string, purchase: any) {
        console.log(`service<purchase>| create(): Enter`);
        console.log(`service<purchase>| create(): $workspace = ${workspace}`);
        console.log(
            `service<purchase>| create(): $data = ${JSON.stringify(purchase)}`,
        );

        return await this.server.create(workspace, purchase);
    }

    async get(workspace: string, item: string, diversion: string = '0') {
        console.log(`service<purchase>| get(): Enter`);
        console.log(`service<purchase>| get(): $workspace = ${workspace}`);
        console.log(`service<purchase>| get(): $item = ${item}`);
        console.log(
            `service<purchase>| get(): $diversion = ${JSON.stringify(
                diversion,
            )}`,
        );
        return this.convertLatest(
            await this.server.get(workspace, item, diversion),
        );
    }

    async list(workspace: string) {
        console.log(`service<purchase>| list(): Enter`);
        console.log(`service<purchase>| list(): $workspace = ${workspace}`);

        const result = await this.server.list(workspace);
        return result.map((item: any) => this.convert(item));
    }

    async listItems(workspace: string) {
        console.log(`service<purchase>| listItems(): Enter`);
        console.log(
            `service<purchase>| listItems(): $workspace = ${workspace}`,
        );

        const result = await this.server.listItems(workspace);
        return result.map((item: any) => this.convertItem(item));
    }

    async update(
        workspace: string,
        item: string,
        purchase: PurchaseOrderUpdateDataObject,
    ) {
        console.log(`service<purchase>| update(): Enter`);
        console.log(`service<purchase>| update(): $workspace = ${workspace}`);
        console.log(`service<purchase>| update(): $item = ${item}`);
        console.log(
            `service<purchase>| update(): $data = ${JSON.stringify(purchase)}`,
        );

        return await this.server.update(workspace, item, purchase);
    }

    async delete(workspace: string, item: string) {
        console.log(`service<purchase>| delete(): Enter`);
        console.log(`service<purchase>| delete(): $workspace = ${workspace}`);
        console.log(`service<purchase>| delete(): $item = ${item}`);

        return await this.server.delete(workspace, item);
    }

    async trace(
        workspace: string,
        item: string,
    ): Promise<PurchaseOrderTrace[]> {
        console.log(`service<purchase>| trace(): Enter`);
        console.log(`service<purchase>| trace(): $workspace = ${workspace}`);
        console.log(`service<purchase>| trace(): $item = ${item}`);

        return await this.server.trace(workspace, item);
    }

    async download(workspace: string, item: string) {
        console.log(`service<purchase>| download(): Enter`);
        console.log(`service<purchase>| download(): $workspace = ${workspace}`);
        console.log(`service<purchase>| download(): $item = ${item}`);

        return await this.server.download(workspace, item);
    }

    private convert(source: any): PurchaseOrder {
        const response: PurchaseOrder = {
            id: source.id,
            closedOn: source.closedOn,
            createdOn: source.createdOn,
            currency: source.currency,
            deletedOn: source.deletedOn,
            downlink: source.downlink,
            externalDataId: source.externalDataId,
            flags: source.flags,
            lastUpdatedOn: source.lastUpdatedOn,
            owner: source.owner,
            releasedOn: source.releasedOn,
            rules: source.rules,
            status: source.status,
            supplier: source.supplier,
            link: source.link,
            uplink: source.uplink,
            versions: source.versions,
            workspace: source.workspace,
        };

        return response;
    }

    private convertLatest(source: any): PurchaseOrderVersion {
        const response: PurchaseOrderVersion = {
            createdOn: source.createdOn,
            id: source.id,
            lastUpdatedOn: source.lastUpdatedOn,
            manifest: source.manifest,
            notes: source.notes,
            owner: source.owner,
            releasedOn: source.releasedOn,
            status: source.status,
        };

        return response;
    }

    private convertItem(source: any): ProductMaterial {
        const result = {
            id: source.id,
            createdOn: source.createdOn,
            description: source.description ?? '',
            lastUpdatedOn: source.lastUpdatedOn,
            material: source.id,
            name: source.name,
            pictures: source.pictures,
            specifications: source.specifications,
            unit: source.unit,
            workspace: source.workspace,
        } as ProductMaterial;

        return result;
    }
}
