import {
    DescriptionItemProps,
    Descriptions,
    Table,
} from '@ianneo/component-library';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    PurchaseComposition,
    PurchaseOrderItem,
    PurchaseOrderTrace,
    PurchaseOrderVersion,
} from '../../../domain/models/purchase.model';

interface OrderOverviewProps {
    data: PurchaseOrderVersion;
    trace: PurchaseOrderTrace[];
}

export default function OrderOverview({ data, trace }: OrderOverviewProps) {
    const { t } = useTranslation();
    const cascasdedTiers = useMemo(() => {
        const tiers = trace.map((item) => item.tier || 0);

        return tiers.length > 0 ? Math.max(...tiers) : 0;
    }, [trace]);

    const columns = useMemo(
        () => [
            {
                title: t('purchase:detail.overview.productName'),
                dataIndex: ['purchaseables', 'name'],
            },
            {
                title: t('purchase:detail.overview.comment'),
                dataIndex: 'comment',
            },
            {
                title: t('purchase:detail.overview.category'),
                dataIndex: ['purchaseables', 'category'],
            },
            { title: t('purchase:detail.overview.price'), dataIndex: 'ppu' },
            {
                title: t('purchase:detail.overview.quantity'),
                dataIndex: 'quantity',
            },
        ],
        [t],
    );

    const descriptions: DescriptionItemProps[] = [
        {
            label: t('purchase:detail.overview.supplier'),
            value: data.owner?.supplier?.seller?.name,
            span: 1.5,
        },
        {
            label: t('purchase:detail.overview.secondarySupplier'),
            value: data.owner?.supplierProducer?.name,
            span: 1.5,
        },
        {
            label: t('purchase:detail.overview.buyDate'),
            value: data.createdOn
                ? new Date(data.createdOn).toLocaleDateString().slice(0, 10)
                : '',
            span: 1.5,
        },
        {
            label: t('purchase:detail.overview.deliveryDate'),
            value: data.deliveryDate
                ? new Date(data.deliveryDate).toLocaleDateString().slice(0, 10)
                : '',
            span: 1.5,
        },
        {
            label: t('purchase:detail.overview.buyer'),
            value: data.owner?.supplier?.owner?.name,
            span: 1.5,
        },
        {
            label: t('purchase:detail.overview.updatedOn'),
            value: data.lastUpdatedOn
                ? new Date(data.lastUpdatedOn).toLocaleDateString().slice(0, 10)
                : '',
            span: 1.5,
        },
        {
            label: t('purchase:detail.overview.cascadedTiers'),
            value: cascasdedTiers.toString(),
            span: 1.5,
        },
        {
            label: t('purchase:detail.overview.template'),
            value: data.owner?.rules?.map((x) => x.code).join(','),
            span: 1.5,
        },
        {
            label: t('purchase:detail.overview.currency'),
            value: data.owner?.currency,
            span: 1.5,
        },
        {
            label: t('purchase:detail.overview.productCount'),
            value: data.manifest?.length.toString(),
            span: 1.5,
        },
    ];

    const expandedRowRender = (item: PurchaseOrderItem) => {
        const expandedColumns = [
            {
                title: 'Name',
                dataIndex: 'name',
                render: (_: unknown, item: PurchaseComposition) => {
                    return item.name?.locales?.find(
                        (x) => x.localeName === 'en',
                    )?.text;
                },
            },
            { title: 'Weight', dataIndex: 'weight' },
        ];

        return (
            <Table
                columns={expandedColumns}
                dataSource={item.purchaseables.compositions}
                style={{ minHeight: 0 }}
                rowKey={'name'}
                pagination={false}
            />
        );
    };

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '60px',
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Descriptions items={descriptions} />
                </div>

                <Table
                    dataSource={data.manifest}
                    columns={columns}
                    rowKey="id"
                    className="custom-table"
                    expandable={{
                        expandedRowRender,
                        expandRowByClick: true,
                        rowExpandable: (record: PurchaseOrderItem) => {
                            return (
                                (record.purchaseables.compositions?.length ||
                                    0) > 0
                            );
                        },
                    }}
                />
            </div>
        </>
    );
}
